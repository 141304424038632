
// const OS = getMobileOperatingSystem()
// export const SIZE = {
//     HEADER_HEIGHT: OS == 'iOS' ? 70 : 70,
//     TOP_MARGIN: OS == 'iOS' ? 74: 74,

// }

export const SIZE = {
    HEADER_HEIGHT: 70,
    TOP_MARGIN: 74,
    MONTH_CELL: {
        WIDTH: 'calc(100% / 7)',
        HEIGHT: 'calc(100% / 7 - 15px)',
        MARGIN_TOP: '15px',
        OUTLINE: '5px solid #00000000',
        OUTLINE_TODAY: '5px solid #00000022',
        COLOR_HOLIDAY: 'black',
        COLOR: 'white',
        FONT_SIZE: '16px',
        FONT_SIZE_SMALL: '14px',
        FONT_SIZE_XSMALL: '12px',
        FONT_WEIGHT: '100'
    }
}