import { Base } from '../../base/components/base'
import { Div } from '../../base/components/native/div'
import { ABSOLUTE, EASE, S, X } from '../../base/helpers/style'
import { PASSIVE } from '../../base/utils/passive-support'
import { SlideX } from './slide-x'

export const SliderX = (options: ISliderOptions) => {

    const base = Base()
    const container = Div()
    const background = Div()
    options.height = options.height ?? options.width
    options.width = options.width ?? Math.min(window.innerWidth, options.maxWidth ?? 425)

    const slides = [
        SlideX(options, 0),
        SlideX(options, 1),
        SlideX(options, 2)
    ]
    container.append(...slides)

    let ox = 0
    let oy = 0
    let box = 0
    let dx = 0
    let dy = 0
    let bdx = 0
    let offset = 0
    let busy = false
    let startTime = 0
    let endTime = 0
    let mode = 'horizontal'

    container.el.addEventListener('touchstart', handleTouchStart, PASSIVE)
    container.el.addEventListener('touchmove', handleTouchMove, PASSIVE)
    container.el.addEventListener('touchend', handleTouchEnd, PASSIVE)
    container.el.addEventListener('touchcancel', handleTouchEnd, PASSIVE)

    function handleTouchStart(e: TouchEvent) {
        if (e.touches.length > 1) return
        oy = e.touches[0].clientY
        ox = e.touches[0].clientX - dx
        box = e.touches[0].clientX - bdx
        startTime = Date.now().valueOf()
        base.emit('touch-start')
    }

    function handleTouchMove(e: TouchEvent) {
        if (e.touches.length > 1) return
        if (busy) return
        if (mode === 'vertical') return
        dx = e.touches[0].clientX - ox
        dy = e.touches[0].clientY - oy
        if (Math.abs(dy) > 25 && !mode) mode = 'vertical'
        if (Math.abs(dx) > 25 && !mode) mode = 'horizontal'
        bdx = e.touches[0].clientX - box
        if (Math.abs(dx) < 30) dx = 0
        if (Math.abs(bdx % options.width) < 30) bdx = Math.round(bdx / options.width) * options.width
        container.style(X(dx))
        background.style(X(bdx / 4))
       if (mode === 'horizontal') base.emit('touch-move')
    }

    function handleTouchEnd(e: TouchEvent) {
        mode = ''
        if (e.touches.length > 1) return
        endTime = Date.now().valueOf()
        let duration = endTime - startTime
        if (duration < 300 || dx > 50 || dx < -50) {
            offset = dx > 0 ? 1 : dx < 0 ? -1 : 0
        } else {
            offset = 0
        }
        busy = true
        container.style(EASE(.25, 'all', 'ease'/* transition / 1.4 */))
        background.style(EASE(.25, 'all', 'ease'/* transition / 1.4 */))
        container.style(X(offset * options.width))
        const round = Math.round(bdx / options.width)
        bdx = round * options.width + offset * options.width
        background.style(X(bdx / 4))
        dx = 0
        setTimeout(handleTransitionEnd, 260) // transition fallback
        base.emit('touch-end')
    }

    function handleTransitionEnd() {
        busy = false
        background.style(EASE(0))
        container.style(EASE(0))
        container.style(X(0))
        slides.forEach(slide => slide.rePosition(offset))
        if (offset !== 0) base.emit('transition-end', offset, offset > 0 ? getLeftSlide() : getRightSlide())
    }

    base.cssClass({
        position: 'relative',
        width: options.width + 'px',
        height: '100vh',
        overflow: 'hidden',
    })

    container.cssClass({
        ...ABSOLUTE,
        left: -options.width + 'px',
        right: options.width + 'px',
        width: options.width * 3 + 'px',
        willChange: 'transform',
    })

    background.cssClass({
        ...ABSOLUTE,
        left: -options.width + 'px',
        right: options.width + 'px',
        width: options.width * 3 + 'px',
        willChange: 'transform',
        zIndex: '0'
    })

    base.append(background, container)

    function getLeftSlide() {
        return slides.find(slide => slide.getOffset() === 0)
    }
    function getRightSlide() {
        return slides.find(slide => slide.getOffset() === 2)
    }

    return Object.assign(base, {
        reset() {
            offset = 0
            slides.forEach(slide => {
                slide.resetPosition()
                slide.empty()
            })
        },
        getSlides() {
            return slides
        },
        getLeftSlide,
        getRightSlide,
        getBackground() {
            return background
        },
        scaleUpBackground() {
            background.style(S(1.2))
        },
        scaleDownBackground() {
            background.style({ ...EASE(.48, 'all', 'ease'), ...S(1) })
            background.style(EASE(0), 100)
        },
        next() {

        },
        prev() {

        }
    })
}

export interface ISliderOptions {
    width?: number
    height?: number
    maxWidth?: number
}