export default {
    P_MONTHS: [
        // '#1a6073', // '#14aa9e', 
        // '#e0629d',
        // '#338969',
        // '#7965aa',

        // '#bf8440', //'#ed932c',
        // '#bf404f', // مرداد
        // '#9f5124',

        // '#706046',
        // '#4b6a89',
        // '#555877', //'#73758c', // اذر

        // '#1c4c82',
        // '#30857e',
        // '#2d876e', // '#14aa9e', 
        
     
        'hsl(180deg,  40%, 40%)',
        'hsl(150deg,  40%, 40%)',
        'hsl(120deg,  40%, 40%)',
       '#8f3d5d',// 'hsl(90deg,   40%, 40%)',
        'hsl(345 50% 40% / 1)',
        'hsl(360deg,  40%, 40%)',
        'hsl(30deg,   40%, 40%)',
        'hsl(330deg,  40%, 40%)',
        'hsl(300deg,  40%, 40%)',
        'hsl(270deg,  40%, 44%)',
        'hsl(240deg,  40%, 46%)',
        'hsl(210deg,  40%, 40%)',
    ],


    G_MONTHS: [
        'hsl(270deg,  40%, 44%)',
        'hsl(240deg,  40%, 46%)',
        'hsl(210deg,  40%, 40%)',
        'hsl(180deg,  40%, 40%)',
        'hsl(150deg,  40%, 40%)',
        'hsl(120deg,  40%, 40%)',
        '#8f3d5d',//   'hsl(90deg,   40%, 40%)',
        'hsl(345 50% 40% / 1)',
        'hsl(360deg,  40%, 40%)',
        'hsl(30deg,   40%, 40%)',
        'hsl(330deg,  40%, 40%)',
        'hsl(300deg,  40%, 40%)'
       
    ]
}