import { ghostify } from '../../base/components/advanced/ghost/ghost'
import { Div } from '../../base/components/native/div'
import { Img } from '../../base/components/native/img'
import { ABSOLUTE, EASE, S } from '../../base/helpers/style'
import { IMAGES } from '../../configs/images'

export const TodoItemOptions = () => {

    const base = Div()

    const back = Div()
    const container = Div()
    const edit = Div('ویرایش')
    const deleteTodo = Div('حذف')
    const postpone = Div('به فردا بیفکن')
    const far = Div('شاید وقتی دیگر')

    container.append(edit, deleteTodo, postpone, far)
    base.append(back, container)

    base.el.onclick = hide
    far.el.onclick = () => base.emit('postpone-far')
    postpone.el.onclick = () => base.emit('postpone')
    deleteTodo.el.onclick = () => base.emit('delete')
    edit.el.onclick = () => base.emit('edit')

    ghostify(container, { bg: '#ffffff77' })

    back.cssClass({
        ...ABSOLUTE,
        position: 'fixed',
        display: 'none',
        backgroundColor: 'transparent',
        zIndex: '99'
    })
    container.cssClass({
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        width: '135px',
        height: '172px',
        color: '#ffffffcc',
        padding: '10px 20px',
        borderRadius: '22px',
        borderBottomLeftRadius: '0px',
        backgroundColor: '#26272cbb',
        transformOrigin: 'bottom left',
        backdropFilter: 'blur(8px)',
        webkitbackdropFilter: 'blur(8px)',
        lineHeight: '36px',
        boxShadow: '6px 6px 16px 0 #00000044',
        zIndex: '100',
        overflow: 'hidden',
        ...EASE(.16),
        ...S(0)
    })

    let t: NodeJS.Timeout
    function show() {
        container.style({...S(1), opacity: 1})
        back.style({ display: 'block' })
        t = setTimeout(() => {
            hide()
        }, 2000)
    }

    function hide() {
        clearTimeout(t)
        container.style({...S(0), opacity: '0'}, 50)
        back.style({ display: 'none' })
    }

    return Object.assign(base, {
        show, hide
    })
}