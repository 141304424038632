import { Base } from '../../base/components/base'
import { EASE, X } from '../../base/helpers/style'
import { PASSIVE } from '../../base/utils/passive-support'
import { COLORS } from '../../configs/config'

export const SimpleSwitchSlider = () => {

    const base = Base()
    let positions: number[] = []

    base.cssClass({
        width: 'calc(50% - 8px)',
        position: 'absolute',
        top: '4px',
        left: '4px',
        bottom: '4px',
        borderRadius: '19px',
        height: '30px',
        backgroundColor: '#00000055',//'#1ed0f9',
        zIndex: '1',
        opacity: '.8',
        ...EASE(.24)
    })

    let x = 0
    let tx = 0
    let dx = 0
    base.el.addEventListener('touchstart', (e: TouchEvent) => {
        x = e.touches[0].pageX
        base.style(X(tx + dx))
        base.style(EASE(0))
    }, PASSIVE)
    base.el.addEventListener('touchmove', (e: TouchEvent) => {
        const { pageX } = e.touches[0]
        tx = pageX - x
        if (tx + dx < 0 || tx + dx > positions[1]) return
        base.style(X(tx + dx))
    })
    base.el.addEventListener('touchend', (e) => {
        const index = findMin(positions, tx)
        move(index)
        base.emit('fix-on', index)
    })

    function move(index: number) {
        base.style({ ...X(positions[index]), ...EASE(.24) })
        tx = 0
        dx = positions[index]
    }

    function fixInPosition(x: number) {
        // positions
    }

    return Object.assign(
        base,
        {
            setPositions(p: number[]) {
                console.log('setPositions', p);
                
                positions = p
            },
            move
        }
    )
}

function findMin(arr: number[], num: number) {
    let dx: number
    let min = 0
    arr.forEach((d, i) => {
        let DX = Math.abs(d - num)
        if (!dx || DX < dx) {
            dx = DX
            min = i
        }
    })
    return min
}