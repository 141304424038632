import idb from '../base/lib/idb'
import ldb from '../base/lib/ldb'
import { uuidv4 } from '../base/utils/id-generator'

const db = idb('emrouz-db')
const init = () =>  {
    console.log('in db init');
    
    return new Promise(async (resolve, reject) => {
        if (!ldb.get('emrouz-client-id')) ldb.save(uuidv4()).as('emrouz-client-id')
        const { version } = await db.info()
        await db.createStore('custom-events', version + 1, { keyPath: 'id', indices: ['_id', 'at', 'modifiedAt', 'title', 'type', 'date'] })
        await db.createStore('todos', version + 2, { keyPath: 'id', indices: ['_id', 'isDone', 'due', 'title'] })
        // await db.createindex('contacts', version + 5, '_id', { unique: true })
        return resolve(true)
    })
}
export default {
    ...db,
    init
}