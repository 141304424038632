import { ghostify } from '../../base/components/advanced/ghost/ghost'
import { Div } from '../../base/components/native/div'
import { EASE, HIDE, SHOW, Y } from '../../base/helpers/style'
import emitter from '../../base/utils/emitter'

export const GotoToday = () => {

    const base = Div('برو به امروز')
    base.el.onclick = () => {
        setTimeout(() => {
            emitter.emit('goto-today')
        }, 100);
    }
    ghostify(base, {bg: '#ffffff40'})

    let route = false, today = false
    emitter.on('show-goto-today', (status: boolean) => {
        console.log('show-goto-today', status);
        
        today = status
        updateVisibility()
    })
    emitter.on('route-changed', (r: string) => {
        route = ['/', '/'].includes(r.split('?')[0])
        updateVisibility()
    })

    function updateVisibility() {
        route && today ? show() : hide()
    }

    function hide() {
        base.style({
            ...HIDE,
            ...Y(-60)
        })
    }

    function show() {
        base.style({
            ...SHOW,
            ...Y(0)
        }, 200)
    }

    base.cssClass({
        ...EASE(.16),
        ...HIDE,
        fontSize:'14px',
        overflow: 'hidden',
        position: 'absolute',
        zIndex: '9999999',
        top: '13px',
        left: '18px',
        color: '#ffffffaa',
        backgroundColor: '#00000040',
        padding: '5px 12px',
        borderRadius: '20px'
    })

    return base
}