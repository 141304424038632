import app from './app'
import ldb from './base/lib/ldb'
import state from './base/services/state'
import store from './base/services/store'
import emitter from './base/utils/emitter'
import { PASSIVE } from './base/utils/passive-support'
import services from './services'
import db from './services/db'


(async () => {
    await db.init()
    store.set('weekstart', ldb.get('weekstart') || 'saturday')
    store.set('mode', ldb.get('mode') || 'j')
    console.log('mode getting', store.get('mode'));
    
    store.set('cursor', ldb.get('cursor') || services.calendar.getDate())
    document.body.appendChild(app.el)
    document.addEventListener('touchstart', () => false, PASSIVE)
    window.onerror = err => alert(JSON.stringify(err, null, 4)) // replace it with report
})()

emitter.on('logged-in', () => {
    // services.sync.work()
    // services.ws.connect()
})
