import { Div } from '../../../../base/components/native/div'
import helpers from '../../../../helpers'
import { IDate } from '../../../../interfaces/date'
import services from '../../../../services'

export const MonthSubTitle = (cursor: IDate, mode = 'g') => {

    const { jy, jm, gy, gm } = cursor
    const { P_MONTHS, G_MONTHS } = helpers.names
    const [njy, njm] = services.calendar.nextMonth(jy, jm)
    const [ngy, ngm] = services.calendar.nextMonth(gy, gm)

    let month = mode === 'g' ? P_MONTHS[jm - 1] : G_MONTHS[gm - 1]
    let nextMonth = mode === 'g' ? P_MONTHS[njm - 1] : G_MONTHS[ngm - 1]
    let year = mode === 'g' ? jy : gy
    const nextYear = mode === 'g' ? njy : ngy
    const title = `${month}${year === nextYear ? ' ' : year} - ${nextMonth} ${nextYear}`

    const base = Div(mode === 'g' ? helpers.digits.toPersian(title) : title)

    base.cssClass({
        fontSize: mode === 'g' ? '18px' : '16px',
        lineHeight: mode === 'g' ? '20px' : '26px',
        fontWeight: '100',
        opacity: '.8',
        color: 'white',
        direction: 'ltr',
        textAlign: mode === 'g' ? 'left' : 'right',
        margin: '0 20px',
        height: '23px'
    })

    return base
}