import { Page } from './page'
import { Div } from '../../base/components/native/div'
import { IRouteParams } from '../../base/lib/router'
import { waitFor } from '../../base/utils/wait'
import { ABSOLUTE, EASE, HIDE, SHOW, Y } from '../../base/helpers/style'
import { Base } from '../../base/components/base'
import helpers from '../../helpers'
import { PageContents } from '../shared/page-contents'
import { MenuWeekStart } from './menu-weekstart'
import { MenuOrientation } from './menu-orientations'
import { MenuHDays } from './menu-h-days'

//  mention https://github.com/jalaali/jalaali-js

export const MenuPage = () => {
    const base = Base()
    const contents = PageContents()
    const title = Div('تقویم فارسی امروز')
    
    const weekStartSwitch = MenuWeekStart()
    const orientationSwitch = MenuOrientation()
    const hDaysSwitch = MenuHDays()
    contents.append(weekStartSwitch, orientationSwitch, hDaysSwitch)
    const version = Div(`v 1.3.6`)
    base.append(title, contents, version)
    version.cssClass({
        margin: '0px 63px',
        textAlign: 'right',
    })
    base.cssClass({ ...helpers.styles.PAGE, ...HIDE, ...Y(60) })
    title.cssClass({
        fontWeight: 'bold',
        fontSize: '22px',
        padding: '14px 60px',
        textAlign: 'right',
    })

    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style(helpers.styles.EXIT_DOWN)
        },
        async enter({ from = '' }: IRouteParams) {
            await waitFor(200)
            base.style(helpers.styles.ENTER)
        }
    }
}