import { ghostify } from '../../../base/components/advanced/ghost/ghost'
import { Div } from '../../../base/components/native/div'
import { CENTER, ROUND } from '../../../base/helpers/style'
import helpers from '../../../helpers'
import { IDate } from '../../../interfaces/date'

export const OccasionRotate = (type: string, date: IDate) => {

    const base = Div()
    const { jy, jm, jd, gy, gm, gd, hy, hm, hd } = date
    const m = type === 'j' ? helpers.digits.toPersian(jd) : type === 'g' ? gd : type === 'h' ? helpers.digits.toArabic(hd) : ''
    const t = Div(m + '')
    t.cssClass({
        fontSize: '28px',
        lineHeight: '28px',
    })


    const month = type === 'j' ? helpers.names.P_MONTHS[jm - 1] : type === 'g' ? helpers.names.G_MONTHS[gm - 1] : type === 'h' ? helpers.names.H_MONTHS[hm - 1] : ''
    const n = type === '' ? 'تکرار نشود' : (month + '<br>هر سال')
    const nn = Div(n)

    const y = ''
    const yy = Div(y)

    base.append(t, nn, yy)
    base.on('mounted', () => {
        base.style({ height: base.el.getBoundingClientRect().width + 'px' })
    })
    ghostify(base, { bg: '#ffffff88' })
    base.cssClass({
        ...ROUND,
        ...CENTER,
        position: 'relative',
        width: 'calc(25% - 5px)',
        backgroundColor: '#00000012',
        color: '#ffffff88',
        flexDirection: 'column',
        lineHeight: '16px',
        fontSize: '14px',
        textAlign: 'center',
        paddingBottom: '10px',
    })

    return Object.assign(base, {
        select() {
            base.style({
                backgroundColor: '#00000042',
                color: '#fff',
            })
        },
        unselect() {
            base.style({
                backgroundColor: '#00000012',
                color: '#ffffff88',
            })
        },
        getValue() {
            return { type, date }
        }
    })
}