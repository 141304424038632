import colors from './colors'
import digits from './digits'
import names from './names'
import styles from './styles'

export default {
    styles: styles,
    colors: colors,
    names: names,
    digits: digits
}