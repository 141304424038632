import { Div } from '../../../base/components/native/div'
import { CENTER } from '../../../base/helpers/style'
import { IDate } from '../../../interfaces/date'
import { OccasionRotate } from './occasion-rotate'

export const OccasionRotations = (date: IDate) => {

    const base = Div()

    const or1 = OccasionRotate('j', date)
    const or2 = OccasionRotate('g', date)
    const or3 = OccasionRotate('h', date)
    const or4 = OccasionRotate('', date)

    const ors = [or1, or2, or3, or4]
    let selected = ors[0]
    selected.select()
    ors.forEach(or => {
        or.el.onclick = () => {
            console.log(selected, or);
            
            selected.unselect()
            or.select()
            selected = or
        }
    })

    base.append(or1, or2, or3, or4)

    base.cssClass({
        ...CENTER,
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
        direction: 'rtl',
        margin: '20px 0',
    })

    return Object.assign(base, {
        getValue: () => selected.getValue()
    })
}