import { Div } from '../../base/components/native/div'
import { Span } from '../../base/components/native/span'
import helpers from '../../helpers'
import { TMode } from '../../interfaces/calendar'
import { IOccasion } from '../../interfaces/occasion'

export const OccasionListItem = (o: IOccasion, view = 'month', mode: TMode) => {
    
    const base = Div()
    const occasionString = mode === 'g' ? o.date.gd?.toString() : (helpers.digits.toPersian(o.date.jd) || o.gd?.toString())
    const num = Span(occasionString)

    let _title = helpers.digits.toPersian(o.title)
    if (o.hd) _title += ` (${o.hd} ${helpers.names.H_MONTHS[o.hm - 1]})`
    else if (mode === 'j' && o.gd) _title += ` (${o.gd} ${helpers.names.G_MONTHS[o.gm - 1].slice(0, 3)})`
    else if (mode === 'g' && o.jd) _title += ` (${o.jd} ${helpers.names.P_MONTHS[o.jm - 1]})`
    const title = Span(_title)
    if (view === 'month') base.append(num)
    base.append(title)

    title.cssClass({
        display: 'flex',
        direction: 'rtl',
        fontSize: '16x',
        color: o.isHoliday ? '#000' : '#fff',
        opacity: '.8',
        lineHeight: '20px'
    })
    num.cssClass({
        marginLeft: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
        width: '16px',
        color: o.isHoliday ? '#000' : '#fff',
    })
    base.cssClass({
        direction: 'rtl',
        display: 'flex',
        margin: '5px 0px',
    })

    return base
}