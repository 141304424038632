
//  mention https://github.com/jalaali/jalaali-js

import { Base } from '../../../base/components/base'
import { Div } from '../../../base/components/native/div'
import { ABSOLUTE, HIDE, EASE, SHOW, Y } from '../../../base/helpers/style'
import router, { IRouteParams } from '../../../base/lib/router'
import state from '../../../base/services/state'
import store from '../../../base/services/store'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { TMode } from '../../../interfaces/calendar'
import occasions from '../../../services/occasions/occasions'
import { EButton } from '../../shared/e-button'
import { EInput } from '../../shared/e-input'
import { OccasionRotations } from './occasion-rotations'

export const AddOccasionPage = () => {
    console.log('-add ocasion page-');

    const base = Base()
    const title = Div('افزودن مناسبت یا رویداد')
    const titleInput = EInput('عنوان مناسبت')
    const save = EButton('ثبت')
    base.append(title, titleInput, save)
    let rotation : any// To be set later FIXME

    save.el.onclick = () => {
        const title = titleInput.getValue()
        if (title === '')  return
        occasions.saveCusoemEvent({ title, ...rotation.getValue() })
        router.back()
    }

    title.cssClass({
        fontSize: '20px',
        textAlign: 'right',
    })
    titleInput.cssClass({
        margin: '40px 0px',
    })
    save.cssClass({
        margin: '20px 0px',
    })
    // base.cssClass({ ...helpers.styles.PAGE, ...HIDE, ...Y(-60), backgroundColor: '#1c2731' })
    base.cssClass({
        ...ABSOLUTE,
        padding: 'calc(env(safe-area-inset-top) + 55px) 20px',
        color: 'white',
        willChange: 'transform,opacity',
        ...HIDE,
        ...EASE(.16),
        ...Y(60),
        backgroundColor: '#1c273188',
    })

    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style({
                ...EASE(.16),
                ...HIDE,
                ...Y(70)
            })
            rotation.remove()
            titleInput.setValue('')
            await waitFor(350)
        },
        async enter({ from = '', data: { date } }: IRouteParams) {
            console.log('data in add occasion', date)
            rotation = OccasionRotations(date)
            base.appendBefore(titleInput, rotation)
            const mode = store.get<TMode>('mode')
            const color = mode === 'j' ? helpers.colors.P_MONTHS[date.jm] : helpers.colors.G_MONTHS[date.gm]
            await waitFor(200)
            titleInput.focus()
            base.style(helpers.styles.ENTER)

            // base.style({
            //     // backgroundColor: color,
            //     ...SHOW,
            //     ...Y(0),
            //     ...EASE(.16)
            // })
        }
    }
}