import { DIM } from '../../configs/config'
import { Ghost, ghostify } from '../../base/components/advanced/ghost/ghost'
import { Base } from '../../base/components/base'
import router from '../../base/lib/router'
import emitter from '../../base/utils/emitter'
import { MenuIconBar } from './menu-icon-bar'
import { CENTER, HIDE, SHOW, Y } from '../../base/helpers/style'
import state from '../../base/services/state'
import store from '../../base/services/store'

export const MenuIcon = () => {

    const height = 3
    const base = Base()
    const top = MenuIconBar(height)
    const middle = MenuIconBar(height)
    const bottom = MenuIconBar(height)
    base.append(top, middle, bottom)

    emitter.on('route-changed', (r: string) => r.split('?')[0] === '/' ? show() : hide())
    emitter.on('mode-changed', (mode: string) => mode === 'g' ? alignLeft() : alignRight())
    base.el.onclick = () => router.goto('/menu')
    ghostify(base)

    base.cssClass({
        ...CENTER,
        position: 'fixed',
        left: store.get('mode') === 'g' ? '0px' : 'auto',
        right: store.get('mode') ? 'auto' : '0px',
        top: 'env(safe-area-inset-top)',
        width: '60px',
        height: '59px',
        padding: '20px',
        transition: 'all .16s',
        zIndex: '99999',
        flexDirection: 'column',
        justifyContent: 'space-between'
    })
    function show() {
        base.style({
            ...SHOW,
            ...Y(0)
        }, { delay: 400 })
    }
    function hide() {
        base.style({
            ...HIDE,
            ...Y(-60)
        })
    }
    function alignLeft() {
        base.style({
            left: '0px',
            right: 'auto'
        })
    }
    function alignRight() {
        base.style({
            left: 'auto',
            right: '0px'
        })
    }
    return base
}