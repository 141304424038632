import { Div } from '../../../../base/components/native/div'
import helpers from '../../../../helpers'

export const HTitle = (d: number) => {

    let base = Div(helpers.digits.toArabic(d))
    
    base.cssClass({
        position: 'absolute',
        zIndex: '99',
        top: '-8px',
        fontSize: '16px',
        fontWeight: '100',
        opacity: '.5',
        '@media (max-width: 375px)': {
            fontSize: '14px'
        },
        '@media (max-width: 320px)': {
            fontSize: '12px'
        }

    })

    return base
}