import { Div } from '../../../../base/components/native/div'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'
import { IOccasion } from '../../../../interfaces/occasion'
import services from '../../../../services'
import { OccasionListItem } from '../../../calendar/occasion-item'

export const MonthOccasions = (cursors: IDate[], mode: TMode) => {

    const base = Div()
    base.on('mounted', () => {
        const title = Div('رویدادها')
        title.cssClass({
            fontSize: '20px',
        })
        base.append(title)
        console.log('-');
        
        const occasions = services.occasions.getAll(cursors)
        
        occasions.forEach((occasion: IOccasion) => {
            base.append(OccasionListItem(occasion, 'month', mode))
        })

    })

    base.cssClass({
        direction: 'rtl',
        padding: '30px 20px',
        opacity: '.85'
    })

    return base
}