import { jalaaliMonthLength, toGregorian, toJalaali } from '../jalaali-js'
import hijri from './hijri'
import * as jalaaliJs from '../jalaali-js'
import { IDate } from '../interfaces/date'
import config from './config'
import state from '../base/services/state'
import { TMode } from '../interfaces/calendar'

function toGregotianFromJalali(jy: number, jm: number, jd: number) {
    return jalaaliJs.toGregorian(jy, jm, jd)
}

function toJalaliFromGregorian(gy: number, gm: number, gd: number) {
    return jalaaliJs.toJalaali(gy, gm, gd)
}

function toHijriFromGregorian(gy: number, gm: number, gd: number) {
    return hijri.fromGregorian(gy, gm, gd)
}

function toGregorianFromHijri(hy: number, hm: number, hd: number) {
    return hijri.toGregorian(hy, hm, hd)
}

function toHijriFromJalali(jy: number, jm: number, jd: number) {
    return hijri.fromJalaali(jy, jm, jd)
}

function toJalaliFromHijri(hy: number, hm: number, hd: number) {
    return hijri.toJalaali(hy, hm, hd)
}

function nextMonth(year: number, month: number, increment = 1) {
    let newMonth = month + increment
    let newYear = year
    if (newMonth < 1) {
        newMonth = 12
        newYear--
    }
    if (newMonth > 12) {
        newMonth = 1
        newYear++
    }
    return [newYear, newMonth]
}

function jMonthLength(jy: number, jm: number) {
    return jalaaliJs.jalaaliMonthLength(jy, jm)
}

function hMonthLength(hy: number, hm: number) {
    return hijri.getMonthLength(hy, hm)
}

function gMonthLength(gy: number, gm: number) { // gm should be 1-based
    return new Date(gy, gm, 0).getDate()
}

function getDate(cursor = new Date()): IDate {
    const gy = cursor.getFullYear(), gm = cursor.getMonth() + 1, gd = cursor.getDate(), wd: number = cursor.getDay()
    const { jd, jm, jy } = toJalaali(gy, gm, gd)
    const { hd, hm, hy } = toHijriFromGregorian(gy, gm, gd)
    return { gy, gm, gd, jd, jm, jy, hd, hm, hy, wd }
}

function getJMonthCursor(date: IDate, direction: number): IDate {
    const { jy, jm, jd } = date
    const [ny, nm] = nextMonth(jy, jm, direction)
    const nd = Math.min(jd, jMonthLength(ny, nm))
    const { gy, gm, gd } = toGregotianFromJalali(ny, nm, nd)
    const { hy, hm, hd } = toHijriFromGregorian(gy, gm, gd)
    const wd = new Date(gy, gm - 1, gd).getDay()
    return { gy, gm, gd, hy, hm, hd, jy: ny, jm: nm, jd: nd, wd }
}

function getGMonthCursor(date: IDate, direction: number): IDate {
    const { gy, gm, gd } = date
    const [ny, nm] = nextMonth(gy, gm, direction)
    const nd = Math.min(gd, gMonthLength(ny, nm))
    const { jy, jm, jd } = toJalaliFromGregorian(ny, nm, nd)
    const { hy, hm, hd } = toHijriFromGregorian(ny, nm, nd)
    const wd = new Date(ny, nm - 1, nd).getDay()
    return { gy: ny, gm: nm, gd: nd, hy, hm, hd, jy, jm, jd, wd }
}

function getJYearCursor(date: IDate, direction: number): IDate {
    const { jy, jm, jd } = date
    const ny = jy + direction
    const nm = jm
    const nd = Math.min(jd, jMonthLength(ny, nm)) // Yeah maybe a leap year
    const { gy, gm, gd } = toGregotianFromJalali(ny, nm, nd)
    const { hy, hm, hd } = toHijriFromGregorian(gy, gm, gd)
    const wd = new Date(gy, gm - 1, gd).getDay()
    return { gy, gm, gd, hy, hm, hd, jy: ny, jm: nm, jd: nd, wd }
}

function getGYearCursor(date: IDate, direction: number): IDate {
    const { gy, gm, gd } = date
    const ny = gy + direction
    const nm = gm
    const nd = Math.min(gd, gMonthLength(ny, nm))
    const { jy, jm, jd } = toJalaliFromGregorian(ny, nm, nd)
    const { hy, hm, hd } = toHijriFromGregorian(ny, nm, nd)
    const wd = new Date(ny, nm - 1, nd).getDay()
    return { gy: ny, gm: nm, gd: nd, hy, hm, hd, jy, jm, jd, wd }
}

function getFirstDayOfMonth(date: IDate, mode: TMode) {
    const { gy, gm, gd, jy, jm, jd, hy, hm, hd } = date
    if (mode === 'j') {
        const { gy, gm, gd } = toGregotianFromJalali(jy, jm, 1)
        const { hy, hm, hd } = toHijriFromGregorian(gy, gm, gd)
        const wd = new Date(gy, gm - 1, gd).getDay()
        return { gy, gm, gd, hy, hm, hd, jy, jm, jd: 1, wd }
    } else {
        const { jy, jm, jd } = toJalaliFromGregorian(gy, gm, 1)
        const { hy, hm, hd } = toHijriFromGregorian(gy, gm, 1)
        const wd = new Date(gy, gm - 1, 1).getDay()
        return { gy, gm, gd: 1, hy, hm, hd, jy, jm, jd, wd }
    }
}

function compareDates(mode: TMode, d1: IDate, d2: IDate) {
    if (mode === 'j') return d1.jy === d2.jy && d1.jm === d2.jm
    return d1.gy === d2.gy && d1.gm === d2.gm /* && d1.gd === d2.gd */
}

export default {
    toHijriFromGregorian,
    toGregorianFromHijri,
    toHijriFromJalali,
    toJalaliFromHijri,
    toJalaliFromGregorian,
    toGregotianFromJalali,
    getFirstDayOfMonth,
    nextMonth,
    hMonthLength,
    jMonthLength,
    gMonthLength,
    getDate,
    getJMonthCursor,
    getGMonthCursor,
    getJYearCursor,
    getGYearCursor,
    compareDates
}

