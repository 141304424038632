import router, { IRouteParams } from '../../../base/lib/router'
import { ABSOLUTE, EASE, HIDE, SHOW, Y } from '../../../base/helpers/style'
import { SliderX } from '../../calendar/slider-x'
import { DaySlideContents } from './day-slide/day-slide-contents'
import calendar from '../../../services/calendar'
import { IDate } from '../../../interfaces/date'
import { IDaySlideContents } from '../../../interfaces/components/day-slide-contents'
import { waitFor } from '../../../base/utils/wait'
import emitter from '../../../base/utils/emitter'
import state from '../../../base/services/state'
import { TMode } from '../../../interfaces/calendar'
import helpers from '../../../helpers'
import services from '../../../services'
import store from '../../../base/services/store'

export const DayPage = () => {

    const W = Math.min(window.innerWidth, 425)
    const H = window.innerHeight
    let _date: IDate
    let jy: number, jm: number, jd: number
    let leftSlideContents: IDaySlideContents, centerSlideContets: IDaySlideContents, rightSlideContents: IDaySlideContents
    const base = SliderX({ width: W, height: H })
    const [leftSlide, centerSlide, rightSlide] = base.getSlides()

    emitter.on('todo-postponed', () => {
        leftSlideContents.fill() // Todo : fix
        rightSlideContents.fill() // Todo : fix
    })
    base.on('transition-end', handleTransitionEnd)
    emitter.on('swipe-down-to-month', () => router.goto('/'))
    base.cssClass({ ...helpers.styles.PAGE, ...Y(60) })

    function handleTransitionEnd(direction: number) {
        // Todo: update query string
        const mode = store.get('mode')
        if (mode === 'g')
            direction = -direction
        const nextDay = getNextDay(_date, direction)
        _date = nextDay
        const furtherDay = getNextDay(nextDay, direction)
        let newSlide = direction > 0 ? base.getLeftSlide() : base.getRightSlide()
        if (mode === 'g') {
            newSlide = direction > 0 ? base.getRightSlide() : base.getLeftSlide()
        }
        newSlide?.empty()
        const slide = DaySlideContents(furtherDay /* , { mode, today: today } */)
        newSlide?.append(slide)
        emitter.emit('date-changed', nextDay)
        slide.fill()
    }

    function getNextDay(date: IDate, direction: number) {
        const { gy, gm, gd } = date
        const nextDate = new Date(gy, gm - 1, gd + direction)
        return calendar.getDate(nextDate)
    }

    async function exit({ query, to }: IRouteParams) {
        base.style(HIDE)
        if (to?.includes('/add-occasions') || to?.includes('/add-todo') || to?.includes('/edit-todo')) {
            base.style({ ...Y(-70), ...HIDE })
            await waitFor(300)
            return
        }
        // base.style(helpers.styles.EXIT_UP)
        if (to==='/') {
            base.style(helpers.styles.EXIT_DOWN)
        }
        if ('jm' in query && query.type === 'temp') {
            centerSlideContets.scaleUpTo(jm)
        }
        setTimeout(() => {
            base.reset()
        }, 260)
    }

    async function enter({ query, data, from }: IRouteParams) {
        await waitFor(200)
        base.style(helpers.styles.ENTER)
        if (from?.includes('/add-todo') || from?.includes('/add-occasions') || from?.includes('/edit-todo')) {
            centerSlideContets.fill() // Todo : fix
            return
        }
        _date = data.gy ? data : services.calendar.getDate()
        centerSlide.empty()
        // Todo: handle missing g and h, you need to calculate all based on j.
        centerSlideContets = DaySlideContents(_date)
        centerSlide.append(centerSlideContets)
        centerSlideContets.scaleUpTo(jd)
        centerSlideContets.fill() // Todo : fix
        setTimeout(() => {
            const mode = store.get<TMode>('mode')

            let direction = mode === 'g' ? -1 : +1

            const prevDay = getNextDay(_date, -direction)
            const nextDay = getNextDay(_date, +direction)
            leftSlideContents = DaySlideContents(nextDay)
            rightSlideContents = DaySlideContents(prevDay)
            leftSlide.append(leftSlideContents)
            rightSlide.append(rightSlideContents)
            leftSlideContents.fill() // Todo : fix
            rightSlideContents.fill() // Todo : fix
        }, 250)
    }

    return Object.assign(base,
        {
            enter,
            exit
        }
    )
}