export const J_OCCASIONS = [
    -1, 1, 'جشن نوروز/جشن سال نو',
    -2, 1, 'جشن نوروز/جشن سال نو',
    -3, 1, 'جشن نوروز/جشن سال نو',
    -3, 1, 'ر. جهانی هواشناسی',
    -4, 1, 'جشن نوروز/جشن سال نو',
    6, 1, 'ر. امید، ر. شادباش نویسی',
    -12, 1, 'ر. جمهوری اسلامی',
    -13, 1, 'جشن سیزده به در',
    23, 1, 'ر. دندانپزشک',
    25, 1, 'ر. ب. عطار نیشابوری',
    29, 1, 'ر. ارتش جمهوری اسلامی ایران',
    30, 1, 'ر. علوم آزمایشگاهی، زاد ر. حکیم سید اسماعیل جرجانی',
    1, 2, 'ر. ب. سعدی',
    3, 2, 'روزب. شیخ بهایی؛ روزم. کارآفرینی؛ ر. معماری',
    9, 2, 'روزشوراها',
    9, 2, 'ر. م. روانشناس و مشاور',
    10, 2, 'ر. م. خلیج فارس',
    12, 2, 'ر. معلم',
    22, 2, 'زادر. مریم میرزاخانی ریاضیدان ایرانی، ر. جهانی زن در ریاضیات',
    25, 2, 'ر. ب. فردوسی',
    27, 2, 'ر. ارتباطات و روابط عمومی',
    28, 2, 'ر. ب. حکیم عمر خیام',
    1, 3, 'ر. بهره وری و بهینه سازی مصرف',
    1, 3, 'ر. ب. ملاصدرا',
    3, 3, 'فتح خرمشهر در عم.ات بیت المقدس و ر. مقاومت، ایثار و پیروزی',
    4, 3, 'ر. دزفول، ر. مقاومت و پایداری',
    -14, 3, 'رحلت امام خمینی',
    -15, 3, 'قیام 15 خرداد',
    25, 3, 'ر. م. گل وگیاه',
    27, 3, 'ر. جهاد کشاورزی',
    1, 4, 'ر. اصناف',
    7, 4, 'انفجار دفتر حزب جمهوری اسلامی و شهادت دکتر بهشتی و 72 نفر از اعضای حزب؛ ر. قوه قضاییه',
    8, 4, 'ر. مبارزه با سلاح های شیمیایی و میکروبی',
    10, 4, 'ر. صنعت و معدن',
    12, 4, 'شلیک به پرواز ۶۵۵ ایران ایر توسط ناو وینسنس. سال ۱۳۶۷',
    14, 4, 'ر. قلم',
    15, 4, 'جشن خام‌خواری',
    22, 4, 'زادر. محمد خوارزمی، ریاضیدان و فیلسوف ایرانی و ر. م. فناوری اطلاعات',
    25, 4, 'ر. بهزیستی و تامین اجتماعی',
    27, 4, 'اعلام پذیرش قطعنامه ۵۹۸ شورای امنیت از سوی ایران سال ۱۳۶۷',
    6, 5, 'ر. ترویج آموزش های فنی و حرفه ای',
    8, 5, 'ر. ب. شیخ شهاب الدین سهروردی',
    14, 5, 'صدور فرمان مشروطیت',
    17, 5, 'ر. خبرنگار',
    28, 5, 'سالر. وقایع ۲۸ مرداد و برکناری دکتر محمد مصدق',
    28, 5, 'سالر. فاجعه آتش زدن سینما رکس آبادان',
    1, 6, 'ر. ب. ابوعلی سینا و ر. پزشک',
    2, 6, 'آغاز هفته دولت',
    4, 6, 'زادر. داراب (کوروش)',
    4, 6, 'ر. کارمند',
    5, 6, 'ر. ب. محمدبن زکریای رازی و ر. داروساز',
    8, 6, 'انفجار در دفتر نخست‌وزیری جمهوری اسلامی ایران، ر. مبارزه با تروریسم',
    11, 6, 'روزصنعت چاپ',
    13, 6, 'ر. ب. ابوریحان بیرونی',
    13, 6, 'ر. تعاون',
    17, 6, 'قیام 17 شهریور',
    19, 6, 'درگذشت آیت الله سید محمود طالقانی',
    21, 6, 'ر. سینما',
    27, 6, 'ر. شعر و ادب پارسی و ر. ب. استاد شهریار',
    30, 6, 'ر. گفتگوی تمدنها',
    31, 6, 'آغاز هفته دفاع مقدس',
    7, 7, 'ر. آتش نشانی و ایمنی',
    8, 7, 'ر. ب. مولوی',
    10, 7, 'مهر روز، جشن مهرگان',
    13, 7, 'ر. نیروی انتظامی',
    14, 7, 'ر. دامپزشکی',
    16, 7, 'ر. م. کودک',
    20, 7, 'ر. ب. حافظ',
    26, 7, 'ر. تربیت بدنی و ورزش',
    29, 7, 'ر. م. کوهنورد',
    1, 8, 'آمار و برنامه ریزی',
    7, 8, 'ر. بزرگداشت کورش کبیر',
    8, 8, 'ر. نوجوان',
    13, 8, 'ر. دانش آموز',
    14, 8, 'ر. فرهنگ عمومی',
    18, 8, 'ر. م. کیفیت',
    24, 8, 'ر. کتاب و کتابخوانی',
    1, 9, 'آذر جشن',
    5, 9, 'ر. بسیج مستضعفان',
    7, 9, 'ر. نیروی دریایی',
    10, 9, 'ر. مجلس',
    13, 9, 'ر. بیمه',
    15, 9, 'ر. حسابدار',
    16, 9, 'ر. دانشجو',
    25, 9, 'ر. پژوهش',
    26, 9, 'ر. حمل و نقل',
    30, 9, 'جشن شب یلدا',
    20, 10, 'قتل امیرکبیر به دستور ناصرالدین شاه قاجار. سال ۱۲۳۰',
    1, 11, 'زادر. فردوسی',
    5, 11, 'جشن نوسره',
    10, 11, 'جشن سده',
    12, 11, 'بازگشت امام خمینی (ره) به ایران',
    19, 11, 'ر. نیروی هوایی',
    -22, 11, 'پیروزی انقلاب اسلامی',
    29, 11, 'جشن سپندارمذگان و ر. عشق',
    5, 12, 'ر. ب. زمین و بانوان',
    5, 12, 'ر. ب. خواجه نصیر الدین طوسی و ر. مهندس',
    7, 12, 'سالر. استقلال کانون وکلای دادگستری و ر. وکیل مدافع',
    7, 12, 'سالر. درگذشت علی اکبر دهخدا',
    15, 12, 'ر. درختکاری',
    -29, 12, 'ر. م. شدن صنعت نفت ایران'
]