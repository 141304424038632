import { IOccasion } from '../../interfaces/occasion'
import { J_OCCASIONS } from './j-occasions'
import { G_OCCASIONS } from './g-occasions'
import { H_OCCASIONS } from './h-occasions'
import { IDate } from '../../interfaces/date'
import calendar from '../calendar'
import db from '../db'
import { shortUUID } from '../../base/utils/id-generator'

let J: IOccasion[] = []
let G: IOccasion[] = []
let H: IOccasion[] = []
fill(J_OCCASIONS, J, 'j')
fill(G_OCCASIONS, G, 'g')
fill(H_OCCASIONS, H, 'h', true)

function fill(source: any[], target: any[], type: string, log=false) {
    for (let i = 0; i <= source.length - 3; i += 3) {
        const [d, m, title] = <[number, number, string, number]>source.slice(i, i + 3)
        const isHoliday = d.toString().includes('-') // minus as a convention for holidays
        let day = Math.abs(typeof d === 'number' ? d : calendar.hMonthLength(1445, m)) // infinity for h occasions that happens on 29th or 30th
     
        let dayKey = `${type}d`
        let monthKey = `${type}m`
        target.push({
            [dayKey]: day,
            [monthKey]: m,
            title: title
                .replace(/ا\./gu, 'امام')
                .replace(/ر\./gu, 'روز')
                .replace(/ج\./gu, 'روز جهانی')
                .replace(/م\./gu, 'ملی')
                .replace(/ب\./gu, 'بزرگداشت')
                .replace(/ح\./gu, 'حضرت')
                .replace(/ع\./gu, '(ع)')
                .replace(/و\./gu, 'ولادت')
                .replace(/ش\./gu, 'شهادت'),
            isHoliday
        })
    }
}

function getDayOccasions(date: IDate) {
    // Todo: improve performance
    const j = J.filter(o => o.m === +date.jm && o.d === +date.jd)
    const g = G.filter(o => o.m === +date.gm && o.d === +date.gd)
    const h = H.filter(o => o.m === +date.hm && o.d === +date.hd || (o.m === +date.hm && date.hMonthLastDay && o.d === 0))
    // for now
    
    return [...j, ...g, ...h]
}

async function getCustomEvents(date: IDate) {
    // Todo: fix performance
    const all = await db.find('custom-events')
    return all.filter(o => {
        switch (o.type) {
            case 'j':
                return o.date.jm === date.jm && o.date.jd === date.jd
            case 'g':
                return o.date.gm === date.gm && o.date.gd === date.gd
            case 'h':
                return o.date.hm === date.hm && o.date.hd === date.hd || (o.date.m === date.hm && date.hMonthLastDay && o.date.d === 0)
            default:
                return o.date.jy === date.jy && o.date.jm === date.jm && o.date.jd === date.jd
        }
    })
}

async function getFullEvents(date: IDate) {
    let _occasions = getDayOccasions(date)
    let _customOccasions = await getCustomEvents(date)
    _customOccasions = _customOccasions.map(c => {
        c.m = c.type === 'j' ? c.date.jm : c.type === 'h' ? c.date.hm : c.type === 'g' ? c.date.gm : c.date.jm
        c.d = c.type === 'j' ? c.date.jd : c.type === 'h' ? c.date.hd : c.type === 'g' ? c.date.gd : c.date.jd
        if (!c.type) c.type = 'j'
        return {
            ...c, ...c.date
        }
    })
    return [..._occasions, ..._customOccasions]
}

export default {
    getPerdDate(date: IDate, options: any) {
        // console.log('getPerdDate', date, options);
        
        const all = [...J, ...G, ...H]
        // todo: check performance
        
        const occasion = all.filter(item => {
            const j = item.jm === date.jm && item.jd === date.jd
            const g = item.gm === date.gm && item.gd === date.gd
            const h = item.hm === date.hm && item.hd === date.hd
            return j || g || h 
        })
        

        return occasion.map(item => {
            return {
                ...item,
                date
            }
        })
    },
    getAll(cursors: IDate[]) {
        // todo: fix h last day
        const [first, last] = [cursors[0], cursors[cursors.length - 1]]
        const all = [...J, ...G, ...H]
        return all
            .filter(item => {
                if (item.jm % 12 < first.jm % 12) return false
                if (item.jm % 12 > last.jm % 12) return false
                if (item.gm % 12 < first.gm % 12) return false
                if (item.gm % 12 > last.gm % 12) return false
                if (item.hm % 12 < first.hm % 12) return false
                if (item.hm % 12 > last.hm % 12) return false
                return true
            })
            .map(item => {
                const date = cursors.find(cursors => {
                    const j = item.jm === cursors.jm && item.jd === cursors.jd
                    const g = item.gm === cursors.gm && item.gd === cursors.gd
                    const h = item.hm === cursors.hm && item.hd === cursors.hd
                    return j || g || h
                })
                if (!date) return
                return { ...item, date }
            })
            .filter(Boolean)
            .sort((a: IOccasion, b: IOccasion) => {
                if (a.date.jy < b.date.jy) return -1
                if (a.date.jy > b.date.jy) return 1
                if (a.date.jm < b.date.jm) return -1
                if (a.date.jm > b.date.jm) return 1
                if (a.date.jd < b.date.jd) return -1
                if (a.date.jd > b.date.jd) return 1
                return 0
            })
    },
    getDayOccasions,
    getCustomEvents,
    getFullEvents,

    saveCusoemEvent(data: any) {
        const at = new Date().toISOString()
        const type = data.type
        const title = data.title
        const id = shortUUID()
        const date = data.date
        db.save('custom-events', { at, title, type, id, date })
    }

}