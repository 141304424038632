import { Base } from '../../../base/components/base'
import { SwitchItem } from './switch-item'
import { SwitchSlider } from './switch-slider'
import emitter from '../../../base/utils/emitter'
import state from '../../../base/services/state'
import ldb from '../../../base/lib/ldb'
import store from '../../../base/services/store'

export const ModeSwitch = (w = 126) => {

    const base = Base('div')
    const g = SwitchItem('میلادی')
    const j = SwitchItem('جـلالی')
    const slider = SwitchSlider()
    base.append(slider, g, j)
    let mode : string
    console.log('mode', mode);
    
    base.on('mounted', () => {
        mode = store.get('mode')
        const { width } = base.el.getBoundingClientRect()
        slider.setPositions([0, w / 2 - 5.5], mode === 'g' ? 0 : 1)
        mode === 'g' ? highlightG() : highlightJ()
    })

    g.el.onclick = moveToG
    j.el.onclick = moveToJ
    slider.on('fix-on', (i: number) => i == 0 ? moveToG() : moveToJ())

    function moveToJ() {
        if (store.get('mode') === 'j') return
        highlightJ()
        slider.move(1)
        store.set('mode', 'j')
        ldb.save('mode', 'j')
        emitter.emit('mode-changed')
    }

    function moveToG() {
        if (store.get('mode') === 'g') return
        highlightG()
        slider.move(0)
        store.set('mode', 'g')
        ldb.save('mode', 'g')
        emitter.emit('mode-changed')
    }

    function highlightG() {
        j.dim()
        g.highlight()
    }

    function highlightJ() {
        g.dim()
        j.highlight()
    }

    base.cssClass({
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '18px',
        backgroundColor: '#0000002e',
        height: '36px',
        margin: '12px auto',
        top: 'env(safe-area-inset-top)',
        zIndex: '0',
        width: '126px',
        fontSize: '14px',
        direction: 'ltr',
        overflow: 'hidden'
    })

    return Object.assign(
        base,
        {
            getValue() {
                return mode
            },
            reset() {
                j.highlight()
                g.dim()
                slider.move(0)
            },
            second() {
                j.dim()
                g.highlight()
                slider.move(1)
            }
        }
    )
}

// q: how to use it?
// a: import { ThemeSwitch } from './theme-switch'

// q: how to cancel a git commit?
// a: git reset --soft HEAD~1