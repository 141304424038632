import { ABSOLUTE, EASE, HIDE, SHOW, Y } from '../base/helpers/style'
import store from '../base/services/store'
import { CS } from '../base/utils/styler'

let mode = store.get('mode')
console.log('mode', mode);

const absolute = {
    position: 'absolute',
    top: '0',
    left: mode === 'g' ? '0' : '',
    right: mode === 'g' ? '' : '0',
    bottom: '0',
}
console.log({absolute});

export default {
    PAGE: <CS>{
        ...EASE(.16),
        color: 'white',
        ...absolute,
        left: 'unset',
        zIndex: '9999',
        willChange: 'opacity,transform',
        
    },
    EXIT_UP: <CS>{
        ...HIDE,
        ...Y(-60),
    },
    EXIT_DOWN: <CS>{
        ...HIDE,
        ...Y(60),
    },
    ENTER: <CS>{
        ...SHOW,
        ...Y(0),
    },

}