import { ISavedTodo, ITodo } from '../interfaces/todo'
import db from './db'

async function save(task: ITodo) {
    await db.save('todos', task)
}
 function byId(id: any) {
    return  db.byId('todos', id)
}
// async function remove(task: ITodo) {
//     await db.delete('todos', task)
// }

async function update(id: number, payload: ISavedTodo) {
    await db.update('todos', id, payload)
}

async function load(date: string) {
    const todos =  await db.find('todos', { index: 'due', value: date })
    return todos.filter((t: ISavedTodo) => !t.isDeleted)
}

export default {
    save,
    load,
    update,
    byId,
}