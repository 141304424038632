import { Base } from '../../../../base/components/base'
import { MonthGrid } from '../month-grid/month-grid'
import { IDate } from '../../../../interfaces/date'
import { TMode } from '../../../../interfaces/calendar'
import { MonthHeader } from './month-header'
import { MonthOccasions } from './month-occasions'
import helpers from '../../../../helpers'
import emitter from '../../../../base/utils/emitter'
import services from '../../../../services'

export const MonthSlideContents = (_date: IDate, mode: TMode, options = { today: {} }) => {
    console.log('-- MonthSlideContents --');
    
    // we need to calculate month first, then pass it to MonthGrid and MonthOccasions
    // first day is based on date and mode

    const date = services.calendar.getFirstDayOfMonth(_date, mode)
    const base = Base()
    const header = MonthHeader(date, mode)
    const grid = MonthGrid(date, mode, options)
    const occasions = MonthOccasions(grid.getDates(), mode) // this to prevent recalcluation of dates

    base.append(header, grid, occasions)

    let oy = 0
    let moved = false
    base.el.addEventListener('touchstart', (e: TouchEvent) => {
        oy = e.touches[0].clientY
    })
    base.el.addEventListener('touchmove', (e: TouchEvent) => {
        if (moved) return
        const dy = e.touches[0].clientY - oy
        if (base.el.scrollTop === 0 && dy > 30) {
            moved = true
            console.log('swipe down to month');
            
            emitter.emit('swipe-down')
        }
    })

    base.cssClass({
        color: 'white',
        overflow: 'hidden',
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        paddingTop: '0',
        backgroundColor: mode === 'g' ? helpers.colors.G_MONTHS[date.gm - 1] : helpers.colors.P_MONTHS[date.jm - 1]
    })

    return base
}