import { Base } from '../../../../base/components/base'
import { Div } from '../../../../base/components/native/div'
import emitter from '../../../../base/utils/emitter'
import { SIZE } from '../../../../configs/size'
import { TMode } from '../../../../interfaces/calendar'

export const MonthWeekDay = (mode: TMode, title: string, index: number, orientation: string, weekStart: string) => {

    const base = Div(title)
    emitter.on('set-orientation', (o: string) => {
        base.style({ textAlign: getAlign(o) })
    })

    base.cssClass({
        marginTop: SIZE.MONTH_CELL.MARGIN_TOP,
        height: SIZE.MONTH_CELL.HEIGHT,
        fontSize: mode === 'g' ? '20px' : '22px',
        textAlign: getAlign(orientation),
        fontWeight: 'bold',
        paddingTop: mode === 'g' ? '10px' : '8px',
        width: 'calc(100% / 7)',
        opacity: index === 6 ? '.7' : '.5',
        color: isWeekHoliday() ? '#000000bf' : 'white',
        '@media (max-width: 375px)': {
            fontSize:  mode === 'g' ? '18px' :'20px'
        },
        '@media (max-width: 320px)': {
            fontSize:  mode === 'g' ? '16px' :'18px'
        }
    })
    
    return base

    function getAlign(orientation?: string) {
        if (orientation === 'column') {
            return mode === 'g' ? 'left' : 'right'
        }
        return 'center'
    }
    
    function isWeekHoliday() {
        if (mode === 'g') {
            return weekStart === 'monday' ? [5, 6].includes(index) : [0, 6].includes(index)
        }
        return index === 6
    }
}

