import { Div } from '../../../base/components/native/div'
import state from '../../../base/services/state'
import store from '../../../base/services/store'
import { TMode } from '../../../interfaces/calendar'
import { YearCard } from './year-card'

export const YearCards  = (year: number, options: any = {}) => {

    const base = Div()

    for (let i = 1; i <= 12; i++) {
        const card = YearCard(year, i, store.get('mode'))
        base.append(card)
    }
    base.cssClass({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 'env(safe-area-inset-top)',
        overflow: 'hidden',
        overflowY: 'auto',
        width: '100%',
        height: 'calc(100vh - 70px - env(safe-area-inset-top) - env(safe-area-inset-bottom))'
    })
    return base
}