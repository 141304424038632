export default {
    toPersian(str: string | number) {
        const P_DIGITS_MAP: any = {
            '0': '۰',
            '1': '۱',
            '2': '۲',
            '3': '۳',
            '4': '۴',
            '5': '۵',
            '6': '۶',
            '7': '۷',
            '8': '۸',
            '9': '۹'
        }
        return String(str).replace(/[0-9]/g, digit => P_DIGITS_MAP[digit])
    },
    toArabic(str: string | number) {
        const ARABIC_DIGITS_MAP: any = {
            '0': '٠',
            '1': '١',
            '2': '٢',
            '3': '٣',
            '4': '٤',
            '5': '٥',
            '6': '٦',
            '7': '٧',
            '8': '٨',
            '9': '٩'
        }
        return String(str).replace(/[0-9]/g, digit => ARABIC_DIGITS_MAP[digit])
    }
}