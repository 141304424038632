import { Div } from '../../base/components/native/div'

export const PageContents = () => {

    const base = Div()
    base.cssClass({
        padding: '0 60px 60px 20px',
        fontSize: '18px',
        direction: 'rtl',
    })

    return base
}