import { EASE, X } from '../../../base/helpers/style'
import { EButton } from '../e-button'

export const SwitchSlider = () => {

    const base = EButton('')
    let positions: number[] = []



    let x = 0
    let tx = 0
    let dx = 0
    base.el.addEventListener('touchstart', (e) => {
        x = e.touches[0].pageX
        base.style(X(tx + dx))
        base.style(EASE(0))
    })
    base.el.addEventListener('touchmove', (e) => {
        const { pageX } = e.touches[0]
        tx = pageX - x
        if (tx + dx < 0 || tx + dx > positions[1]) return
        base.style(X(tx + dx))
    })
    base.el.addEventListener('touchend', (e) => {
        const index = findMin(positions, tx + dx)
        move(index)
        base.emit('fix-on', index)
    })

    function move(index: number, duration = .24) {
        base.style({ ...X(positions[index]), ...EASE(duration) })
        tx = 0
        dx = positions[index]
    }

    function fixInPosition(x: number) {
        // positions
    }

    base.cssClass({
        width: 'calc(50% - 3px)',
        position: 'absolute',
        marginTop: '0',
        left: '4px',
        borderRadius: '14px',
        height: '28px',
        top: '4px',
        bottom: '4px',
        backgroundColor: '#00000055',
        zIndex: '1',
    })
    
    return Object.assign(
        base,
        {
            setPositions(p: number[], init = 0) {
                positions = p
                move(init, 0)
            },
            setPostion(index: number) {
                move(index, 0)
            },
            move
        }
    )
}

function findMin(arr: number[], num: number) {
    const distances = arr.map((n) => Math.abs(n - num))
    const min = Math.min(...distances)
    return distances.indexOf(min)
}