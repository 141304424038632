import ldb from '../base/lib/ldb'
let HIJRI_OVERRIDES = [

    [1445, 1, 0],
    [1445, 3, 1],
    [1445, 4, 1],
    [1445, 5, 1],
    [1445, 6, 1],
    [1445, 7, 1],
    [1445, 9, 1],
    [1445, 10, 1],
    [1446, 2, -1],
]
export default {
    getConfig: () => {
        return ldb.get('emrouz-app-config') || { showGDays: true, showHDays: false, orientation: 'column', weekStart: 'sunday' }
    },
    setConfig(config: any) {
        ldb.save(config).as('emrouz-app-config')
    },
    getHijriOverrides() {
        const fromApp = ldb.get('EMROUZ_HIJRI_OVERRIDES') || []
        return [...HIJRI_OVERRIDES, ...fromApp]
    }
}