import { Div } from '../../../../base/components/native/div'
import emitter from '../../../../base/utils/emitter'
import helpers from '../../../../helpers'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'
import services from '../../../../services'
import config from '../../../../services/config'

export const MonthHTitle = (cursor: IDate, mode: TMode) => {
    // const hMonthLength = calendar.hMonthLength(hy, hm)
    const { hy, hm } = cursor
    const [nextHy, nextHm] = services.calendar.nextMonth(hy, hm)
    const [furtherHy, furtherHm] = services.calendar.nextMonth(nextHy, nextHm)
    const ThreeHMonthWithinAJMonth = false //furtherHyJy === nextHyJy && furtherHmJm === nextHmJm

    const f1 = helpers.names.H_MONTHS[hm - 1]
    const f2 = hy === nextHy ? '' : (' ' + hy)
    const f3 = helpers.names.H_MONTHS[nextHm - 1]
    const f4 = nextHy === furtherHy && ThreeHMonthWithinAJMonth ? '' :  nextHy
    const f5 = ThreeHMonthWithinAJMonth ? (` - ${helpers.names.H_MONTHS[furtherHm - 1]} ${furtherHy}`) : ''
    const title = helpers.digits.toArabic(`${f1}${f2} - ${f3} ${f4}${f5}`)
    const base = Div(title)
    // emitter.on('toggle-h-days', () =>{
    //     base.style({ display: config.getConfig().showHDays ? 'block' : 'none' })
    // })
    base.cssClass({ 
        fontSize: '16px',
        fontWeight: '100',
        opacity: '.4',
        color: 'white',
        direction: 'rtl',
        padding: '0 20px 20px',
        height: '20px',
        textAlign: mode === 'g' ? 'left' : 'right',
        // display: config.getConfig().showHDays ? 'block' : 'none'
    })

    return base
}