import { Div } from '../../../../base/components/native/div'
import { EASE } from '../../../../base/helpers/style'
import helpers from '../../../../helpers'
import { IDate } from '../../../../interfaces/date'

export const DayHTitle = (mode: string, date: IDate) => {

    const { hy, hm, hd } = date
    let titleString = helpers.digits.toPersian(`${hd} ${helpers.names.H_MONTHS[hm - 1]} ${hy} `)

    const base = Div(titleString)

    base.cssClass({
        fontWeight: '100',
        fontSize: '16px',
        textAlign: mode === 'g' ? 'left': 'right',
        direction: 'rtl',
        margin: '5px 20px 10px',
        opacity: '0.5',
        color: 'white',
        wordSpacing: '2px',
        ...EASE(.24),
    })

    return base

}