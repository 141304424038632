import * as jalaaliJs from '../jalaali-js'
import calendar from './calendar'
import config from './config'

const GREGORIAN_EPOCH = 1721425.5
const ISLAMIC_EPOCH = 1948439.5

function jdt_to_gregorian(jdt: number) {
    var wjd, depoch, quadricent, dqc, cent, dcent, quad, dquad, yindex, dyindex, gy, yearday, leapadj
    wjd = Math.floor(jdt - 0.5) + 0.5
    depoch = wjd - GREGORIAN_EPOCH
    quadricent = Math.floor(depoch / 146097)
    dqc = depoch % 146097
    cent = Math.floor(dqc / 36524)
    dcent = dqc % 36524
    quad = Math.floor(dcent / 1461)
    dquad = dcent % 1461
    yindex = Math.floor(dquad / 365)
    gy = (quadricent * 400) + (cent * 100) + (quad * 4) + yindex
    if (!((cent == 4) || (yindex == 4))) {
        gy++
    }
    yearday = wjd - gregorian_to_jdt(gy, 1, 1)
    leapadj = ((wjd < gregorian_to_jdt(gy, 3, 1)) ? 0 : (leap_gregorian(gy) ? 1 : 2))

    var gm = Math.floor((((yearday + leapadj) * 12) + 373) / 367)
    var gd = (wjd - gregorian_to_jdt(gy, gm, 1)) + 1

    return { gy, gm, gd }
}

function islamic_to_jdt(hy: number, hm: number, hd: number) {
    return (hd +
        Math.ceil(29.5 * (hm - 1)) +
        (hy - 1) * 354 +
        Math.floor((3 + (11 * hy)) / 30) +
        ISLAMIC_EPOCH) - 1
}


function leap_gregorian(gy: number) {
    return ((gy % 4) == 0) && (!(((gy % 100) == 0) && ((gy % 400) != 0)))
}

function gregorian_to_jdt(gy: number, gm: number, gd: number) {
    return (GREGORIAN_EPOCH - 1) +
        (365 * (gy - 1)) +
        Math.floor((gy - 1) / 4) -
        Math.floor((gy - 1) / 100) +
        Math.floor((gy - 1) / 400) +
        Math.floor((((367 * gm) - 362) / 12) + (gm <= 2 ? 0 : leap_gregorian(gy) ? -1 : -2) + gd)
}

function jdt_to_islamic(jdt: number) {
    jdt = Math.floor(jdt) + 0.5
    const hy = Math.floor(((30 * (jdt - ISLAMIC_EPOCH)) + 10646) / 10631)
    const hm = Math.min(12, Math.ceil((jdt - (29 + islamic_to_jdt(hy, 1, 1))) / 29.5) + 1)
    const hd = (jdt - islamic_to_jdt(hy, hm, 1)) + 1

    // Check for Hijri ovevrrides
    let shift = config.getHijriOverrides().find(([y, m]) => {
        return y === hy && m === hm
    })?.[2] || 0

    if (hd - shift === 0) {
        const [pvrvHy, prevHM] = calendar.nextMonth(hy, hm, -1)
        return {
            hy: pvrvHy,
            hm: prevHM,
            hd: calendar.hMonthLength(pvrvHy, prevHM)
        }
    }
    if (hd  > calendar.hMonthLength(hy, hm)) {
        const [nextHY, nextHM] = calendar.nextMonth(hy, hm)
        return {
            hy: nextHY,
            hm: nextHM,
            hd: 1
        }
    }

    return { hy, hm, hd: hd - shift}
}

export default {
    fromGregorian(gy: number, gm: number, gd: number) {
        const julian = gregorian_to_jdt(gy, gm, gd)
        return jdt_to_islamic(julian)
    },
    toGregorian(hy: number, hm: number, hd: number) {
        // const offset = config.getHijriOverrides().find(({ y, m }) => y === hy && m === hm)?.offset ?? 0
        const julian = islamic_to_jdt(hy, hm, hd /* + offset */)
        return jdt_to_gregorian(julian)
    },
    toJalaali(hy: number, hm: number, hd: number) {
        const { gy, gm, gd } = this.toGregorian(hy, hm, hd)
        return jalaaliJs.toJalaali(gy, gm, gd)
    },
    fromJalaali(jy: number, jm: number, jd: number) {
        const { gy, gm, gd } = jalaaliJs.toGregorian(jy, jm, jd)
        return this.fromGregorian(gy, gm, gd)
    },
    getMonthLength(hy: number, hm: number) {
        const julian1 = islamic_to_jdt(hy, hm, 1)
        const hijriOverride1 = config.getHijriOverrides().find(([y, m]) => {
            return y === hy && m === hm
        })
        const shift1 = hijriOverride1 ? hijriOverride1[2] : 0
        const [nextYear, nextMonth] = calendar.nextMonth(hy, hm)
        const julian2 = islamic_to_jdt(nextYear, nextMonth, 1)
        const hijriOverride2 = config.getHijriOverrides().find(([y, m]) => {
            return y === nextYear && m === nextMonth
        })
        const shift2 = hijriOverride2 ? hijriOverride2[2] : 0
        return julian2 - julian1 - shift1 + shift2
        // Todo // Check for Hijri ovevrrides fix it
        return Math.floor(julian2 - julian1) 
    }
    // Todo: find a better hijri to julian script
}