
import { Base } from '../../../base/components/base'
import { Div } from '../../../base/components/native/div'
import { ABSOLUTE, HIDE, EASE, SHOW, Y } from '../../../base/helpers/style'
import router, { IRouteParams } from '../../../base/lib/router'
import state from '../../../base/services/state'
import store from '../../../base/services/store'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { TMode } from '../../../interfaces/calendar'
import { Todo } from '../../../models/todo'
import todos from '../../../services/todos'
import { EButton } from '../../shared/e-button'
import { EInput } from '../../shared/e-input'

export const AddTodoPage = () => {
    const base = Base()
    const title = Div('افزودن کار جدید')
    const titleInput = EInput('عنوان')
    const save = EButton('ثبت')
    base.append(title, titleInput, save)

    title.cssClass({
        fontSize: '20px',
        textAlign: 'right',
    })
    titleInput.cssClass({
        margin: '40px 0px',
    })
    save.cssClass({
        margin: '20px 0px',
    })

    base.cssClass({
        ...ABSOLUTE,
        padding: 'calc(env(safe-area-inset-top) + 55px) 20px',
        color: 'white',
        willChange: 'transform,opacity',
        ...HIDE,
        ...EASE(0),
        ...Y(70)
    })



    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style({
                ...EASE(.16),
                ...HIDE,
                ...Y(70)
            })
            titleInput.setValue('')
            await waitFor(350)
        },
        async enter({ from = '', data: { date } }: IRouteParams) {
            console.log('entering add todo page', date);
            
            base.appendBefore(titleInput)
            const mode = store.get<TMode>('mode')
            const color = mode === 'j' ? helpers.colors.P_MONTHS[date.jm] : helpers.colors.G_MONTHS[date.gm]
            // Todo: check if it's a holiday and due
            await waitFor(200)
            titleInput.focus()
            save.el.onclick = saveTodo
            title.once('key-enter', saveTodo) // Todo: not working

            function saveTodo() {
                const title = titleInput.getValue()
                if (title === '')  return
                const todo = Todo(title, new Date(date.gy, date.gm - 1, date.gd))
                todos.save(todo)
                router.back()
            }

            base.style({
                backgroundColor: color,
                ...SHOW,
                ...Y(0),
                ...EASE(.16)
            })
        }
    }
}