import { Base } from '../../../base/components/base'
import { Div } from '../../../base/components/native/div'
import { EASE, HIDE, SHOW, Y } from '../../../base/helpers/style'
import state from '../../../base/services/state'
import store from '../../../base/services/store'
import emitter from '../../../base/utils/emitter'
import helpers from '../../../helpers'
import { TMode } from '../../../interfaces/calendar'
import { YearCards } from './year-cards'
import { YearTitle } from './year-title'

export const YearSlide = (year: number, options: any = {}) => {
    const base = Base()
    const _title = store.get('mode') === 'g' ? year.toString() : 'سال ' + helpers.digits.toPersian(year)
    const title = YearTitle(_title)
    let oy = 0
    let ox = 0
    let moved = false
    let movedX = false
    base.el.addEventListener('touchstart', (e: TouchEvent) => {
        oy = e.touches[0].clientY
        ox = e.touches[0].clientX
    })
    base.el.addEventListener('touchmove', (e: TouchEvent) => {
        if (moved) return
        if (movedX) return
        const dy = Math.abs(e.touches[0].clientY - oy)
        const dx = Math.abs(e.touches[0].clientX - ox)

        if (dx > 30 && dx > dy) {
            movedX = true
        }
        if (dy > 30) {
            moved = true
            emitter.emit('swipe-up')
        }
    })
    base.el.addEventListener('touchend', (e: TouchEvent) => {
        moved = false
        movedX = false
    })
    base.el.addEventListener('touchcancel', (e: TouchEvent) => {
        moved = false
        movedX = false
    })
    const cards = YearCards(year, options)
    base.append(title, cards)
    base.cssClass({
        paddingTop: 'env(safe-area-inset-top)',
        overflow: 'hidden',
        overflowY: 'auto',
        width: '100%',
        height: '100%'
    })

    return Object.assign(base, {
        scaleUpTo(month: number) {
            // grid.scaleUp(month)
            title.style({
                ...HIDE,
                ...Y(-30)
            })
        },
        scaleDownTo(month: number) {
            // grid.scaleDown(month)
            title.style({
                ...SHOW,
                ...Y(0)
            }, 300)
        }
    })
}