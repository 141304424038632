import { DIM } from '../../configs/config'
import { Ghost, ghostify } from '../../base/components/advanced/ghost/ghost'
import { Base } from '../../base/components/base'
import router from '../../base/lib/router'
import emitter from '../../base/utils/emitter'
import { MenuIconBar } from '../menu/menu-icon-bar'
import { Div } from '../../base/components/native/div'
import { CENTER, HIDE, SHOW, Y } from '../../base/helpers/style'
import { Img } from '../../base/components/native/img'
import { IMAGES } from '../../configs/images'

export const BackIcon = () => {

    const base = Div()
    const back = Img(IMAGES.ICONS.BACK, { width: 23 })
    base.append(back)
    emitter.on('route-changed', (r: string) => r == '/' ? hide() : show())
    base.el.onclick = () => router.back()

    ghostify(base)

    base.cssClass({
        position: 'fixed',
        right: '0px',
        top: 'env(safe-area-inset-top)',
        width: '60px',
        height: '59px',
        padding: '20px',
        transition: 'all .16s',
        zIndex: '99999999999',
        ...CENTER,
        ...HIDE,
        color: 'white',
        lineHeight: '23px',
        fontSize:   '27px',
        flexDirection: 'column',
        justifyContent: 'space-between'
    })

    function show() {
        base.style({
            ...SHOW,
            ...Y(0)
        }, {delay: 200})
    }
    function hide() {
        base.style({
            ...HIDE,
            ...Y(60)
        })
    }

    return base
}