import { Div } from '../../../../base/components/native/div'
import { ABSOLUTE, CENTER, ROUND, WH } from '../../../../base/helpers/style'
import { SubTitle } from '../month-cell/sub-title'
import { MainTitle } from '../month-cell/main-title'
import { HTitle } from '../month-cell/h-title'
import config from '../../../../services/config'
import router from '../../../../base/lib/router'
import { SIZE } from '../../../../configs/size'
import { IDate } from '../../../../interfaces/date'
import { TMode } from '../../../../interfaces/calendar'
import services from '../../../../services'

export const MonthCell = (data: IDate, mode: TMode, options: any = { today : {}}) => {

    const base = Div()
    const mainTitle = MainTitle(data, mode)
    const gTitle = SubTitle(data, mode)
    const hTitle = HTitle(data.hd)

    services.occasions.getCustomEvents(data).then(occasions => {
        if (occasions.length > 0) {
            const circle = Div()
            circle.cssClass({
                ...WH(5),
                ...ROUND,
                backgroundColor: '#00000077',
                position: 'absolute',
                left: '1px',
            })
            base.append(circle)
        }
    })

    base.append(mainTitle, gTitle, hTitle)

    // gTitle.style({ display: config.getConfig().showGregorianDays ? 'block' : 'none' })
    hTitle.style({ display: config.getConfig().showHDays ? 'block' : 'none' })
    base.el.onclick = () => {
        router.goto('/day', data)
    }
    if (isToday()) {
        const circle = Div()
        circle.cssClass({
            ...WH('120%'),
            ...ROUND,
            backgroundColor: '#00000038',
            // border:'1px dashed black',
            // backdropFilter: 'invert(1)',
            position: 'absolute',
        })
        base.append(circle)
    }
    base.cssClass({
        ...ROUND,
        marginTop: SIZE.MONTH_CELL.MARGIN_TOP,
        position: 'relative',
        width: 'calc(100% / 7)',
        height: SIZE.MONTH_CELL.HEIGHT,
        outline: 'none',
        // outline: isToday() ? '5px solid #00000022' : '5px solid #00000000',
        color: isHoliday() ? '#000000bf' : 'white',
        cursor: 'pointer',
        ...CENTER,
        '&:hover': {
            backgroundColor: isToday() ? '#00000077' : '#00000022'
        },
    })

    return Object.assign(base, {
        getValue() {
            return data
        },
        toggleHDay(show: boolean) {
            // Toodo: remove the listener
            hTitle.style({ display: show ? 'block' : 'none' })
        }
    })

    function isToday() {
        if (options.today.gd !== data.gd) return false
        if (options.today.gm !== data.gm) return false
        if (options.today.gy !== data.gy) return false
        return true
    }

    function isHoliday() {
        const jwe = mode === 'j' && data.wd === 5
        const gVDays = [0, 6]
        const gwe = mode === 'g' && gVDays.includes(data.wd)
        const occasions = services.occasions.getPerdDate(data, options)
        const isAHoliday = occasions.some(o => o.isHoliday)
        return isAHoliday || jwe || gwe
        // Todo: handle holidays by country or get feedbacks from users
    }
}
