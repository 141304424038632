import { Base } from '../../base/components/base'
import { ISimpleSwitchItem } from '../../interfaces/simple-switch'
import { SimpleSwitchItem } from './simple-switch-item'
import { SimpleSwitchSlider } from './simple-switch-slider'

export const SimpleSwitch = (items: ISimpleSwitchItem[] = []) => {

    const base = Base('div')
    let _value = items[0].value
    let _current: any = null
    let _stops: any = []
    items.forEach((item, i) => {
        const itemBase = SimpleSwitchItem(item)
        _stops.push(itemBase)
        itemBase.dim()
        itemBase.el.onclick = () => {
            if (_current) _current.dim()
            _current = itemBase
            _current.highlight()
            slider.move(i)
            _value = item.value
            base.emit('change', item.value)
        }
        base.append(itemBase)
    })
    const slider = SimpleSwitchSlider()

    base.on('mounted', () => {
        const { width } = base.el.getBoundingClientRect()
        slider.setPositions([0, width / 2])
        _current = _stops[0]
        _current.highlight()
    })
    slider.on('fix-on', (i: number) => {
        if (_current) _current.dim()
        _current = _stops[i]
        _current.highlight()
        base.emit('change', items[i].value)
    })

    base.append(slider)

    base.cssClass({
        direction: 'ltr',
        backgroundColor: '#00000033',
        position: 'relative',
        borderRadius: '20px',
        height: '38px',
        zIndex: '0',
        color: 'white',
        boxSizing: 'content-box',
        // border: '1px solid white',
        width: '158px',
        overflow: 'hidden',
        fontSize: '15px'
    })


    return Object.assign(
        base,
        {
            getValue() {
                return _value
            },
            setValue(value: any) {
                const index = items.findIndex(item => item.value === value)
                if (index === -1) return
                if (_current) _current.dim()
                _current = _stops[index]
                _current.highlight()
                slider.move(index)
                _value = value
            }
        }
    )
}