import { Base } from '../../base/components/base'
import { Div } from '../../base/components/native/div'
import { ABSOLUTE, CENTER } from '../../base/helpers/style'
import { ISliderOptions } from './slider-x'

export const SlideX = (options: ISliderOptions, _offset: number) => {
    let offset = _offset
    const base = Base()
    const top = Div()
    const bot = Div()
    top.style({...ABSOLUTE, top: '0', height: '5px'})
    bot.style({...ABSOLUTE, top:'unset', bottom: '0', height: '5px'})
    // base.append(top, bot)
    base.cssClass({
        ...CENTER,
        ...ABSOLUTE,
        flexDirection: 'column',
        width: options.width + 'px',
        left: offset * options.width + 'px',
    })
    return Object.assign(base, {
        rePosition(_offset: number) {
            offset = _offset + offset           
            if (offset > 2) offset = 0
            if (offset < 0) offset = 2
            base.style({ left: offset * options.width + 'px' })
        },
        resetPosition() {
            offset = _offset
            base.style({ left: _offset * options.width + 'px' })
        },
        getOffset() {
            return offset
        }
    })
}