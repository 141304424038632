import { Div } from '../../../base/components/native/div'
import router from '../../../base/lib/router'
import state from '../../../base/services/state'
import store from '../../../base/services/store'
import helpers from '../../../helpers'
import colors from '../../../helpers/colors'
import { TMode } from '../../../interfaces/calendar'
import { IDate } from '../../../interfaces/date'
import services from '../../../services'

export const YearCard = (year: number, month: number, mode: TMode) => {

    const color = Div()
    const title = mode === 'g' ? helpers.names.G_MONTHS[month - 1] : helpers.names.P_MONTHS[month - 1]
    const titleDiv = Div(title)
    const base = Div()
    base.append(color, titleDiv)
    if (mode === 'g') {
    } else {
        const { gy: sy, gm: sm } = services.calendar.toGregotianFromJalali(year, month, 1)
        const { gy: ey, gm: em } = services.calendar.toGregotianFromJalali(year, month, services.calendar.jMonthLength(year, month))
        const string = `${helpers.names.G_MONTHS[sm - 1]} ${sy !== ey ? sy : ''} - ${helpers.names.G_MONTHS[em - 1]} ${ey}`
        const g = Div(string)
        g.cssClass({
            fontSize: '16px',
            top: '17px',
            left: '20px',
            opacity: '.6',
            fontWeight: '100',
            position: 'absolute',
        })
        base.append(g)
    }


    base.el.onclick = () => {
        mode === 'g' ? setGCursor() : setJCursor()
        router.goto('/')
    }

    function setJCursor() {
        const { jy, jm, jd } = store.get('cursor')
        const nm = month
        const nd = Math.min(jd, services.calendar.jMonthLength(jy, nm))
        const { gy, gm, gd } = services.calendar.toGregotianFromJalali(jy, nm, nd)
        const { hy, hm, hd } = services.calendar.toHijriFromGregorian(gy, gm, gd)
        store.set('cursor',{
            jy,
            jm: nm,
            jd: nd,
            gy,
            gm,
            gd,
            hy,
            hm,
            hd,
            wd: new Date(gy, gm - 1, gd).getDay()
        })
    }

    function setGCursor() {
        const { gy, gm, gd } = store.get<IDate>('cursor')
        const nm = month
        const nd = Math.min(gd, services.calendar.gMonthLength(gy, nm))

        const { jy, jm, jd } = services.calendar.toJalaliFromGregorian(gy, nm, nd)
        const { hy, hm, hd } = services.calendar.toHijriFromGregorian(gy, nm, nd)
        store.set('cursore', {
            jy,
            jm,
            jd,
            gy,
            gm: nm,
            gd: nd,
            hy,
            hm,
            hd,
            wd: new Date(gy, gm - 1, gd).getDay()
        })
    }
    titleDiv.cssClass({
        fontSize: '20px',
        fontWeight: 'normal',
        opacity: '.8'
    })
    color.cssClass({
        width: '10px',
        marginLeft: mode === 'g' ? '0' : '30px',
        marginRight: mode === 'g' ? '30px' : '0',
        height: '40px',
        backgroundColor: mode === 'g' ? helpers.colors.G_MONTHS[month - 1] : helpers.colors.P_MONTHS[month - 1]
    })
    base.cssClass({
        padding: '10px 28px',
        height: '100%',
        overflow: 'hidden',
        width: 'calc(100%)',
        textAlign: mode === 'g' ? 'left' : 'right',
        position: 'relative',
        color: 'white',
        display: 'flex',
        direction: mode === 'g' ? 'ltr' : 'rtl',
        // borderTopRightRadius: '10px',
        // borderTopLeftRadius: '10px',
        // margin: '0 10px',
    })

    return base
}