import { Div } from '../../../../base/components/native/div'
import helpers from '../../../../helpers'
import { IMonthCellData } from '../../../../interfaces/month-cell-options'

export const SubTitle = (data: IMonthCellData, mode: 'g' | 'j' | 'h') => {

    let dd = helpers.digits.toPersian(data.jd)
    if (mode === 'j') dd = data.gd.toString()
    const base =  Div(dd)

    base.cssClass({
        position: 'absolute',
        bottom:  mode === 'g' ? '-10px' : '-6px',
        fontSize: mode === 'g' ? '22px' : '18px',
        fontWeight: '100',
        opacity: '.5',
        zIndex: '99',
        '@media (max-width: 375px)': {
            fontSize: mode === 'g' ?  '20px' : '16px'
        },
        '@media (max-width: 320px)': {
            fontSize: mode === 'g' ? '18px' : '14px'
        }
    })

    return base
}