import { Base } from '../../base/components/base'
import { Div } from '../../base/components/native/div'
import { EASE } from '../../base/helpers/style'
import { ICustomOccasion, IOccasion } from '../../interfaces/occasion'
import { ITodo } from '../../interfaces/todo'
import { OccasionListItem } from './occasion-item'
import { TodoItem } from './todo-item'

export const TodosList = (todos: ITodo[], view: string, mode: string) => {
    
    const base = Base()
    const title = Div(view === 'month' ? 'کارهای این ماه' : 'کارهای امروز ')
    title.cssClass({ fontWeight: 'normal', fontSize: '20px' })
    base.append(title)

  
    todos.forEach(o => {
        const todo = TodoItem(o, view, mode)
        base.append(todo)
    })

    base.cssClass({
        textAlign: 'right',
        color: 'white',
        position: 'relative',
        ...EASE(.36)
    })

    return base
}