export const H_OCCASIONS = [
    15, 9, 'و. ا. حسن مجتبی ع.',
    18, 9, 'شب قدر',
    19, 9, 'ضربت خوردن ح. علی ع.',
    -21, 9, 'ش. ح. علی ع.',
    22, 9, 'شب قدر',
    -1, 10, 'عید سعید فطر',
    -2, 10, 'تعطیل به مناسبت عید سعید فطر',
    -25, 10, 'ش. ا. جعفر صادق ع.',
    1, 11, 'و. ح. معصومه (س) و روز دختران',
    11, 11, 'و. ا. رضا ع.',
    'lstday', 11, 'ش. ا. محمد تقی ع.',
    7, 12, 'ش. ا. محمد باقر ع.',
    9, 12, 'روز عرفه',
    -10, 12, 'عید سعید قربان',
    15, 12, 'و. ا. علی النقی الهادی ع.',
    -18, 12, 'عید سعید غدیر خم',
    20, 12, 'و. ا. موسی کاظم ع.',
    -9, 1, 'تاسوعای حسینی',
    -10, 1, 'عاشورای حسینی',
    12, 1, 'ش. ا. زین العابدین ع.',
    -20, 2, 'اربعین حسینی',
    -28, 2, 'رحلت رسول اکرم؛ش. ا. حسن مجتبی ع.',
    '-lstday', 2, 'ش. ا. رضا ع.',
    1, 3, 'هجرت پیامبر اکرم از مکه به مدینه',
    -8, 3, 'ش. ا. حسن عسکری ع.',
    12, 3, 'میلاد رسول اکرم به روایت اهل سنت',
    -17, 3, 'میلاد رسول اکرم و ا. جعفر صادق ع.',
    8, 4, 'و. ا. حسن عسکری ع.',
    10, 4, 'وفات ح. معصومه (س)',
    5, 5, 'و. ح. زینب (س) و روز پرستار و بهورز',
    -3, 6, 'ش. ح. فاطمه زهرا (س)',
    20, 6, 'و. ح. فاطمه زهرا (س) و روز مادر',
    1, 7, 'و. ا. محمد باقر ع.',
    3, 7, 'ش. ا. علی النقی الهادی ع.',
    10, 7, 'و. ا. محمد تقی ع.',
    -13, 7, 'و. ا. علی ع. و روز پدر',
    15, 7, 'وفات ح. زینب (س)',
    25, 7, 'ش. ا. موسی کاظم ع.',
    -27, 7, 'مبعث رسول اکرم (ص)',
    3, 8, 'و. سالار شهیدان، ا. حسین ع. و روز پاسدار',
    4, 8, 'و. ابوالفضل العباس ع. و روز جانباز',
    5, 8, 'و. ا. زین العابدین ع.',
    11, 8, 'و. علی اکبر ع. و روز جوان',
    -15, 8, 'و. ح. قائم (عج) و جشن نیمه شعبان'
]