import { Div } from '../../../../base/components/native/div'
import helpers from '../../../../helpers'
import { IDate } from '../../../../interfaces/date'

export const MainTitle = (data: IDate, mode: string) => {
    
    let dd = data.gd.toString()
    if (mode === 'j') dd = helpers.digits.toPersian(data.jd)
    const base = Div(dd)

    base.cssClass({
        fontSize: mode === 'g' ? '28px' : '34px',
        fontWeight: 'bold',
        lineHeight: '22px',
        marginTop: mode === 'g' ? '4px' : '2px',
        zIndex: '2',
        opacity: '.8',
        fontFamily: 'Samim', // Todo: not applied
        '@media (max-width: 320px)': {
            fontSize: mode === 'g' ? '24px' : '30px'
        },
        '@media (max-width: 375px)': {
            fontSize: mode === 'g' ? '26px' : '32px'
        },
    })
    
    return base
}