import { Base } from '../../../../base/components/base'
import { Div } from '../../../../base/components/native/div'
import state from '../../../../base/services/state'
import store from '../../../../base/services/store'
import emitter from '../../../../base/utils/emitter'
import helpers from '../../../../helpers'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'
import { DayHTitle } from './day-h-title'
import { DayOccasions } from './day-occasions'
import { DaySubTitle } from './day-sub-title'
import { DayTasks } from './day-tasks'
import { DayTitle } from './day-title'

export const DaySlideContents = (date: IDate, options: any = {}) => {

    const base = Base()
    const { jm, gm , gy, gd} = date
    const mode = store.get<TMode>('mode')

    const color = mode === 'j' ? helpers.colors.P_MONTHS[jm -1] : helpers.colors.G_MONTHS[gm -1]
    const title = DayTitle(mode, date)
    const subtitle = DaySubTitle(mode, date)
    const hTitle = DayHTitle(mode, date)
    const rest = Div()
    base.append(title, subtitle, hTitle, rest)

    async function fill() {
        rest.empty()
        const occasions = DayOccasions(date, mode)
        const tasks = DayTasks(date, mode)
        rest.append(occasions, tasks)
    }


    let oy = 0
    let moved = false
    base.el.addEventListener('touchstart', (e: TouchEvent) => {
        oy = e.touches[0].clientY
    })
    base.el.addEventListener('touchmove', (e: TouchEvent) => {
        if (moved) return
        const dy = e.touches[0].clientY - oy
        if (base.el.scrollTop === 0 && dy > 30) {
            moved = true
            emitter.emit('swipe-down-to-month')
        }
    })

    base.cssClass({
        paddingTop: 'env(safe-area-inset-top)',
        overflow: 'hidden',
        overflowY: 'auto',
        backgroundColor: color,
        width: '100%',
        height: '100%',
    })

    return Object.assign(base,
        {
            fill,
            scaleUpTo(day: number) {
                // grid.scaleUp(month)
                // title.style({
                //     ...HIDE,
                //     ...Y(60)
                // })
                // title.style({
                //     ...SHOW,
                //     ...Y(0)
                // }, 160)
                // subtitle.style({
                //     ...HIDE,
                //     ...Y(60)
                // })
                // subtitle.style({
                //     ...SHOW,
                //     opacity: '.8',
                //     ...Y(0)
                // }, 160)
                // hTitle.style({
                //     ...HIDE,
                //     ...Y(60)
                // })
                // hTitle.style({
                //     ...SHOW,
                //     opacity: '.6',
                //     ...Y(0)
                // }, 160)
                // occasions.style({
                //     ...HIDE,
                //     ...Y(60)
                // })
                // occasions.style({
                //     ...SHOW,
                //     ...Y(0)
                // }, 160)
                // add.style({
                //     ...HIDE,
                //     ...Y(60)
                // })
                // add.style({
                //     ...SHOW,
                //     ...Y(0)
                // }, 220)
            },
            scaleDownTo(month: number) {
                // grid.scaleDown(month)
                // title.style({
                //     ...HIDE,
                //     ...Y(-30)
                // }, 300)
                // subtitle.style({
                //     ...HIDE,
                //     ...Y(-30)
                // }, 300)
                // hTitle.style({
                //     ...HIDE,
                //     ...Y(-30)
                // }, 300)
            }
        })
}
