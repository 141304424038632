import router, { IRoute, IRouteParams } from '../../../base/lib/router'
import { MonthSlideContents } from './month-slide/month-slide-contents'
import { SliderX } from '../../calendar/slider-x'
import calendar from '../../../services/calendar'
import emitter from '../../../base/utils/emitter'
import { waitFor } from '../../../base/utils/wait'
import { ModeSwitch } from '../../shared/switch/mode-switch'
import state from '../../../base/services/state'
import helpers from '../../../helpers'
import { IDate } from '../../../interfaces/date'
import services from '../../../services'
import { TMode } from '../../../interfaces/calendar'
import ldb from '../../../base/lib/ldb'
import store from '../../../base/services/store'

export const MonthPage = () => {

    const today = services.calendar.getDate()

    const base = SliderX({ maxWidth: 425 })
    const [lSlide, cSlide, rSlide] = base.getSlides()
    base.append(ModeSwitch())
    base.cssClass(helpers.styles.PAGE)

    let moving = false
    emitter.on('mode-changed', init)
    emitter.on('set-week-start', init)
    emitter.on('set-h-visibility', init)
    emitter.on('goto-today', () => init(today))
    emitter.on('swipe-down', handleSwipeDown )
    base.on('transition-end', handleOnTransitionEnd)
    base.on('touch-move', () => moving = true)
    base.on('touch-end', () => {
        
        moving = false
        console.log('touch end', moving);
    })

    return Object.assign(base, { enter, exit })

    async function enter({ from, params, query }: IRouteParams) {
        let start = new Date().valueOf()
        init()
        let end = new Date().valueOf()
        let diff = end - start
        console.log('1.', diff);
        
        await waitFor(Math.min((200 - diff), 200))
        base.style(helpers.styles.ENTER)
        if (from?.includes('/menu')) return
        // if (from?.includes('/day')) return
        // m = m || query?.jm
        // y = y || query?.jy
    }

    async function exit({ to }: IRouteParams) {
        base.style(helpers.styles.EXIT_UP)
        if (to?.includes('/year')) {
            base.style(helpers.styles.EXIT_DOWN)
        }
    }

    function init(date?: IDate) {
        let start = new Date().valueOf()
        base.reset()
        if (!date) {
            date = store.get('cursor') || services.calendar.getDate()
            state.set('cursor', date)
        }
        const mode = store.get('mode') || localStorage.getItem('mode') as TMode || 'j'
        const cursor = getMonthSameDay(date, mode, 0)
        console.log('3.', new Date().valueOf() - start);
        emitter.emit('show-goto-today', !services.calendar.compareDates(mode, cursor, today))
        store.set('cursor', cursor)
        console.log('4.', new Date().valueOf() - start);
        cSlide.append(MonthSlideContents(cursor, mode, { today }))
        console.log('5.', new Date().valueOf() - start);
        setTimeout(() => {
           fillRestSlides(date as IDate, mode)
        }, 200)
    }

    function fillRestSlides(date: IDate, mode: TMode) {
        let direction = mode === 'g' ? -1 : +1
        const ld = getMonthSameDay(date, mode, +direction)
        const rd = getMonthSameDay(date, mode, -direction)
        lSlide.append(MonthSlideContents(ld, mode, { today }))
        rSlide.append(MonthSlideContents(rd, mode, { today }))
    }

    function getMonthSameDay(date: IDate, mode: TMode, direction: number): IDate {
        return mode === 'g' ? calendar.getGMonthCursor(date, direction) : calendar.getJMonthCursor(date, direction)
    }

    function handleOnTransitionEnd(d: number, newSlide: any) {
        const mode = store.get('mode')
        let direction = mode === 'g' ? -d : +d
        const nextMonth = getMonthSameDay(store.get('cursor'), mode, direction)
        const furtherMonth = getMonthSameDay(nextMonth, mode, direction)
        const newMonth = MonthSlideContents(furtherMonth, mode, { today })
        newSlide?.empty()
        newSlide?.append(newMonth)
        emitter.emit('show-goto-today', !services.calendar.compareDates(mode, nextMonth, today))
        store.set('cursor',nextMonth)
        emitter.emit('date-changed', nextMonth)
    }

    function handleSwipeDown() {
        setTimeout(() => {
            
            console.log('swipe down', moving);
            if (moving) return
            router.goto('/year')
        }, 10);
        }
}