import { Page } from '../page'
import router, { IRouteParams } from '../../../base/lib/router'
import { ABSOLUTE, EASE, HIDE, SHOW, Y } from '../../../base/helpers/style'
import { SliderX } from '../../calendar/slider-x'
import { YearSlide } from './year-slide'
import helpers from '../../../helpers'
import { waitFor } from '../../../base/utils/wait'
import state from '../../../base/services/state'
import { IDate } from '../../../interfaces/date'
import { TMode } from '../../../interfaces/calendar'
import ldb from '../../../base/lib/ldb'
import emitter from '../../../base/utils/emitter'
import services from '../../../services'
import store from '../../../base/services/store'
import { Sky } from './sky'

export const YearPage = () => {

    const W = window.innerWidth
    const H = window.innerHeight
    let ls: any, cs: any, rs: any // Todo: fix this
    const base = SliderX({ width: W, height: H })
    const [leftSlide, centerSlide, rightSlide] = base.getSlides()
    emitter.on('swipe-up', () => router.back())
    base.on('transition-end', handleOnTransitionEnd)
    //  (o: number) => { // Bad practice, it triggers on any transition
    //     jy += o
    //     const newJy = jy + o
    //     const newSlide = o > 0 ? base.getLeftSlide() : base.getRightSlide()
    //     newSlide?.empty()
    //     newSlide?.append(YearSlide(newJy/* , { selected: newJy === JY && newJm === JM && jd } */))
    // })
    // const sky = Sky()
    // base.append(sky)

    base.cssClass({ ...helpers.styles.PAGE, ...HIDE, ...Y(-60), backgroundColor: '#1c2731' })

    // background.style({
    //     background: 'url(https://wallpaperaccess.com/full/148418.jpg)',
    //     backgroundSize: 'cover',
    // })


    function handleOnTransitionEnd(d: number, newSlide: any) {
        const mode = store.get('mode') as TMode
        let direction = mode === 'g' ? -d : +d
        const cursor = store.get('cursor') as IDate
        const nextYear = mode === 'g' ? services.calendar.getGYearCursor(cursor, direction) : services.calendar.getJYearCursor(cursor, direction)
        const furtherMonth = mode === 'g' ? services.calendar.getGYearCursor(nextYear, direction) : services.calendar.getJYearCursor(nextYear, direction)
        const newYear = YearSlide(mode === 'g' ? furtherMonth.gy : furtherMonth.jy, mode)
        newSlide?.empty()
        newSlide?.append(newYear)
        store.set('cursor',nextYear)
        emitter.emit('date-changed', nextYear)
    }

    return Object.assign(base, {
        async exit({ query }: IRouteParams) {
            base.style(helpers.styles.EXIT_UP)
            setTimeout(() => {
                base.reset()
            }, 260)
            // sky.stopAnimation()

        },
        async enter({ query }: IRouteParams) {
            await waitFor(200)
            const { jy, gy } = store.get('cursor') as IDate
            const y = store.get('mode') === 'g' ? gy : jy
            const d = store.get('mode') === 'g' ? -1 : +1
            ls = YearSlide(y + d)
            cs = YearSlide(y)
            rs = YearSlide(y - d)
            leftSlide.append(ls)
            centerSlide.append(cs)
            rightSlide.append(rs)
            // sky.startAnimation()
            // cs.scaleUpTo(jm)
            // base.scaleUpBackground()
            // setTimeout(() => {
            //     cs.scaleDownTo(jm)
            //     base.scaleDownBackground()
            // }, 50)
            base.style(helpers.styles.ENTER)
        }
    })
}