import { Div } from '../../../../base/components/native/div'
import router from '../../../../base/lib/router'
import { IMAGES } from '../../../../configs/images'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'
import services from '../../../../services'
import { OccasionListItem } from '../../../calendar/occasion-item'
import { ActionButton } from './action-button'

export const DayOccasions = (date: IDate, mode: TMode) => {

    const base = Div()
    const title = Div('مناسبت های امروز')
    base.append(title)
    base.on('mounted', fill)

    async function fill() {
        const customEvents = await services.occasions.getCustomEvents(date)
        console.log(customEvents);
        customEvents.forEach(occasion => {
            base.append(OccasionListItem(occasion, 'day', mode))
        })
        const occasions = services.occasions.getPerdDate(date)
        occasions.forEach(occasion => {
            base.append(OccasionListItem(occasion, 'day', mode))
        })
        const addOccasion = ActionButton('افزودن مناسبت', IMAGES.ICONS.BIRTHDAY_CAKE)
        base.append(addOccasion)
        addOccasion.el.onclick = () => router.goto('/add-occasions', { date: { ...date } })
    }

    title.cssClass({
        fontWeight: 'normal',
        fontSize: '20px'
    })
    base.cssClass({
        padding: '20px',
        position: 'relative',
        direction: 'rtl',
    })

    return base
}