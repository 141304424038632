import { Div } from '../../../../base/components/native/div'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'
import services from '../../../../services'
import { MonthHTitle } from './month-h-title'
import { MonthSubTitle } from './month-sub-title'
import { MonthTitle } from './month-title'

export const MonthHeader = (date: IDate, mode: TMode) => {

    const base = Div()

    const title = MonthTitle(date, mode)
    const subTitle = MonthSubTitle(date, mode)
    const hTitle = MonthHTitle(date, mode)
    base.append(title, subTitle, hTitle)

    base.cssClass({
        position: 'sticky',
        top: '0',
        padding:'calc(env(safe-area-inset-top) + 20px) 0 20px',
        backdropFilter: 'blur(10px)',
        webkitbackdropFilter: 'blur(10px)',

        zIndex:'999'
    })

    return base
}