import { Div } from '../../../../base/components/native/div'
import { EASE } from '../../../../base/helpers/style'
import helpers from '../../../../helpers'
import { IDate } from '../../../../interfaces/date'

export const DaySubTitle = (mode: string, date: IDate) => {

    const { jd, jm, jy, gy, gm, gd } = date
    let titleString = helpers.digits.toPersian(`${jd} ${helpers.names.P_MONTHS[jm - 1]} ${jy}`)
    if (mode === 'j') titleString = `${gd} ${helpers.names.G_MONTHS[gm - 1]} ${gy}`

    const base = Div(titleString)

    base.cssClass({
        fontSize: mode === 'g' ? '22px' : '18px',
        textAlign: mode === 'g' ? 'left' : 'right',
        direction: mode === 'g' ? 'rtl' : 'ltr',
        margin: mode === 'g' ? '8px 20px 0px' : '0px 20px 0px',
        height: mode === 'g' ? '23px' : '16px',
        lineHeight: mode === 'g' ? '16px' : '16px',
        opacity: '.8',
        color: 'white',
        wordSpacing: '2px',
        ...EASE(.24),
    })

    return base

}