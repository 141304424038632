import { Div } from '../../base/components/native/div'
import emitter from '../../base/utils/emitter'
import config from '../../services/config'
import { SimpleSwitch } from '../shared/simple-switch'

export const MenuOrientation = () => {

    const base = Div()
    const title = Div('چینش تقویم')
    const weekStartSwitch = SimpleSwitch([
        { title: 'عمودی', value: 'column' },
        { title: 'افقی', value: 'row' },
    ])
    let c = config.getConfig()

    weekStartSwitch.on('change', (value: string) => {
        c = config.getConfig()
        config.setConfig({ ...c, orientation: value })
        emitter.emit('set-orientation', value)
    })
    base.append(title, weekStartSwitch)
    setTimeout(() => {
        // Todo: add after mount event to simple switch
        weekStartSwitch.setValue(c.orientation)
    }, 100);

    title.cssClass({
        textAlign: 'right',
        opacity: '.8',
        fontSize: '16px',
        marginBottom: '10px',
    })
    base.cssClass({
        margin: '10px 0 20px',
    })

    return base
}