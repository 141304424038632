export const IMAGES = {
    ICONS: {
        EDIT: '/images/edit-icon.svg',
        LOCK: '/images/lock.svg',
        CONFIRM: '/images/confirm-icon.svg',
        DELETE: '/images/trash.svg',
        CANCEL: '/images/cancel-icon.svg',
        ADD: '/images/add-icon.svg',
        DOWNLOAD: '/images/download-icon.svg',
        BACK: '/images/arrow-right.svg',
        GOOGLE: '/images/google.svg',
        SPIRAL: '/images/google.svg',
        STAR: '/images/star-icon.svg',
        BIRTHDAY_CAKE: '/images/birthday-cake-icon.svg',
        TASK: '/images/task-icon.svg',
        DOTS:'images/dots-y-icon.svg',
        X: '//x-icon.svg',
        ARROW_DOWN: '/images/arrow-down.svg',
    }
}