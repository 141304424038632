import { Div } from '../../../../base/components/native/div'
import router from '../../../../base/lib/router'
import { IMAGES } from '../../../../configs/images'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'
import todos from '../../../../services/todos'
import { TodosList } from '../../../calendar/todos'
import { ActionButton } from './action-button'

export const DayTasks = (date: IDate, mode: TMode) => {

    const base = Div()
    base.on('mounted', fill)

    async function fill() {
        const { jm, gm , gy, gd} = date
        const queryDate = new Date(gy, gm -1, gd).toISOString().slice(0, 10)
        const tasks = await todos.load(queryDate)
        const list = TodosList(tasks, 'day', mode)
        const addTask = ActionButton('افزودن کار جدید', IMAGES.ICONS.TASK)
        addTask.el.onclick = () => router.goto('/add-todo', { date: {...date} })
        base.append(list, addTask)
    }
    
    base.cssClass({
        direction: 'rtl',
        padding: '20px',
        position: 'relative',
        
    })

    return base
}