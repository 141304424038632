import { shortUUID } from '../base/utils/id-generator'
import { ITodo } from '../interfaces/todo'

export function Todo(title: string, date: Date): ITodo {

    return {
        title,
        isDone: false,
        due: date.toISOString().slice(0, 10),
        at: new Date().toISOString(),
    }
}
