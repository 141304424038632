import { Div } from '../../../../base/components/native/div'
import { Span } from '../../../../base/components/native/span'
import { EASE } from '../../../../base/helpers/style'
import helpers from '../../../../helpers'
import { IDate } from '../../../../interfaces/date'

export const DayTitle = (mode: string, date: IDate) => {

    const { wd, jd, jm, jy, gy, gm, gd } = date
    const weekd = mode === 'j' ? helpers.names.P_WEEKDAY[(wd + 1) % 7] : helpers.names.G_WEEKDAY[wd]
    const mname = mode === 'j' ? helpers.names.P_MONTHS[jm - 1] : helpers.names.G_MONTHS[gm - 1]
    let titleString = `${gd} ${mname} ${gy}`
    if (mode === 'j') titleString = helpers.digits.toPersian(`${jd} ${mname} ${jy}`)
    const wdTitle = Span(weekd)
    wdTitle.style({fontWeight: '100', marginRight: mode === 'g' ? '5px' : '0', marginLeft: mode === 'j' ? '5px' : '0'})
    const base = Div(titleString)
    base.prepend(wdTitle)

    base.cssClass({
        fontSize: mode === 'g' ? '22px' : '26px',
        fontWeight: 'bold',
        fontFamily: mode === 'g' ? 'sans-serif' : 'Samim',
        textAlign: mode==='g'? 'left': 'right',
        margin: '50px 20px 0px',
        color: 'white',
        wordSpacing: '1px',
        ...EASE(.24),
    })

    return base

}