import { ghostify } from '../../../../base/components/advanced/ghost/ghost'
import { Div } from '../../../../base/components/native/div'
import { Img } from '../../../../base/components/native/img'
import { CENTER, EASE } from '../../../../base/helpers/style'
import { IMAGES } from '../../../../configs/images'

export const ActionButton = (_title: string, _icon: string) => {

    const base = Div()
    const title = Div(_title)
    const icon = Img(_icon, {width: 20})

    base.append( icon,title)
    ghostify(base, {bg: '#fff'})

    icon.cssClass({
        opacity: '.8',
        marginBottom: '4px'
    })
    title.cssClass({
        marginRight: '10px',
        marginTop: '3px',
        opacity: '.8',
    })
    base.cssClass({
        width: 'fit-content',
        backgroundColor: '#00000055',
        position: 'relative',
        // border: '1px solid #ffffff47',
        margin: '30px 0',
        color: '#fff',
        borderRadius: '18px',
        height: '37px',
        padding: '0px 15px',
        fontSize: '14px',
        display: 'flex',
        boxShadow: '3px 3px 7px 0px #00000017',
        textAlign: 'left',
        ...CENTER,
        ...EASE(.16),
    })

    return base

}