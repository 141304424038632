import { View } from './components/view'
import { MonthPage } from './components/pages/month-page/month-page'
import { MenuPage } from './components/pages/menu-page'
import { Base } from './base/components/base'
import router from './base/lib/router'
import { YearPage } from './components/pages/year-page/year-page'
import { DayPage } from './components/pages/day-page/day-page'
import { BackIcon } from './components/shared/back-icon'
import { AddOccasionPage } from './components/pages/add-occasion/add-occasion-page'
import emitter from './base/utils/emitter'
import { AddTodoPage } from './components/pages/add-todo/add-todo-page'
import { EditTodoPage } from './components/pages/add-todo/edit-todo-page'
import { MenuIcon } from './components/menu/menu-icon'
import { IDate } from './interfaces/date'
import helpers from './helpers'
import { TMode } from './interfaces/calendar'
import { ModeSwitch } from './components/shared/switch/mode-switch'
import { Div } from './base/components/native/div'
import { EASE, HIDE, SHOW } from './base/helpers/style'
import state from './base/services/state'
import services from './services'
import { GotoToday } from './components/shared/goto-today'
import ldb from './base/lib/ldb'
import store from './base/services/store'

const view = View()
// const dayView = DayPage() // for desktop page
const app = Base('div')
const menuIcon = MenuIcon()
const backIcon = BackIcon()
const today = GotoToday()
// const swtch = ModeSwitch()
// Todo: mounter not working on the parent
app.append(today, menuIcon, backIcon, view)

app.cssClass({
    margin: '0 auto',
    height: 'calc(100vh + env(safe-area-inset-top) + env(safe-area-inset-bottom))',
    position: 'relative',
})


emitter.on('date-changed', setBackgroundColor)
setBackgroundColor(services.calendar.getDate())
function setBackgroundColor(cursor: IDate) {
    const mode = store.get('mode') ||  ldb.get('mode') || 'j'
    const { gm, jm } = cursor
    const color = mode === 'g' ? helpers.colors.G_MONTHS[gm - 1] : helpers.colors.P_MONTHS[jm - 1]
    document.body.style.backgroundColor = color
}

const routes = {
    '/': MonthPage,
    '/year': YearPage,
    '/day': DayPage,
    '/menu': MenuPage,
    '/add-occasions': AddOccasionPage,
    '/add-todo': AddTodoPage,
    '/edit-todo': EditTodoPage,
}

router.init({ routes, view, root: '' })

export default app
