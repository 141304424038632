import { ghostify } from '../../base/components/advanced/ghost/ghost'
import { Base } from '../../base/components/base'
import { Div } from '../../base/components/native/div'
import { Img } from '../../base/components/native/img'
import { EASE } from '../../base/helpers/style'
import router from '../../base/lib/router'
import emitter from '../../base/utils/emitter'
import { IMAGES } from '../../configs/images'
import { ISavedTodo, ITodo } from '../../interfaces/todo'
import todos from '../../services/todos'
import { TodoItemOptions } from './todo-item-options'

export const TodoItem = (todo: ISavedTodo, view: string, mode: string) => {

    const base = Base()
    const confirm = Img(IMAGES.ICONS.CONFIRM, { width: 22 })
    const title = Div(todo.title)
    const dots = Img(IMAGES.ICONS.DOTS, { width: 20})
    const options = TodoItemOptions()
    base.append(confirm, title, dots, options)


    options.on('delete', async () => {
        base.remove()
        todo.isDeleted = true
        await todos.update(todo.id, todo)
    })
    options.on('edit', async () => {
        router.goto('/edit-todo', { id: todo.id })
        // await todos.update(todo.id, todo)
    })
    options.on('postpone', async () => {
        base.remove()
        const latestTodoValue = <ISavedTodo>await todos.byId(todo.id)
        console.log(latestTodoValue);
        
        const today = new Date(latestTodoValue.due)
        today.setDate(today.getDate() + 1)
        latestTodoValue.due = today.toISOString().split('T')[0]
        await todos.update(todo.id, latestTodoValue)
        emitter.emit('todo-postponed')
    })
    options.on('postpone-far', async () => {
        base.remove()
        const today = new Date(todo.due)
        today.setDate(today.getDate() + 7)
        todo.due = today.toISOString().split('T')[0]
        await todos.update(todo.id, todo)
    })

    confirm.el.onclick = async () => {
        todo.isDone = !todo.isDone
        confirm.style({ opacity: todo.isDone ? '.8' : '.1' })
        title.style({ textDecoration: todo.isDone ? 'line-through' : 'none' })
        title.style({ opacity: todo.isDone ? '.5' : '.8', })
        await todos.update(todo.id, todo)
    }

    dots.el.onclick = () => {
        options.show()
        base.style({ backgroundColor: '#00000055' })
        base.style({ backgroundColor: '' }, 100)
    }

    ghostify(base, {bg: '#ffffff77'})
    dots.cssClass({
        position: 'absolute',
        left: '0px',
        opacity: '.3',
        backdropFilter: 'blur(10px)',
        webkitbackdropFilter: 'blur(10px)',

    })
    confirm.cssClass({
        opacity: todo.isDone ? '.8' : '.1',
        marginLeft: '10px',
        marginTop: '-3px',
    })
    title.cssClass({
        opacity: todo.isDone ? '.5' : '1',
        textDecoration: todo.isDone ? 'line-through' : 'none',
        fontSize: '16px',
    })
    base.cssClass({
        display: 'flex',
        textAlign: 'right',
        position: 'relative',
        direction: 'rtl',
        padding: '13px 0',
        fontSize: '16px',
        ...EASE(.5)
    })

    return base
}