import { Div } from '../../../base/components/native/div'
import { EASE } from '../../../base/helpers/style'
import state from '../../../base/services/state'
import store from '../../../base/services/store'
import { TMode } from '../../../interfaces/calendar'

export const YearTitle = (title: string) => {

    const base = Div(title)
    base.cssClass({
        fontSize: '28px',
        fontWeight: 'bold',
        textAlign: store.get('mode') === 'g' ? 'left' : 'right',
        margin: '8px 60px 0px',
        color: 'white',
        ...EASE(.24),
    })

    return base
}