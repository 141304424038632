const names = {
    P_MONTHS: [
        'فروردین',
        'اردیبهشت',
        'خرداد',
        'تیر',
        'مرداد',
        'شهریور',
        'مهر',
        'آبان',
        'آذر',
        'دی',
        'بهمن',
        'اسفند'
    ],
    H_MONTHS: [
        'محرم',
        'صفر',
        'ربیع‌الاول',
        'ربیع‌الثانی',
        'جمادی‌الاول',
        'جمادی‌الثانی',
        'رجب',
        'شعبان',
        'رمضان',
        'شوال',
        'ذیقعده',
        'ذیحجه'
    ],
    G_MONTHS: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],
    G_WEEKDAY: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ],
    G_SHORT_WEEKDAY: [
        'S',
        'M',
        'T',
        'W',
        'T',
        'F',
        'S',
    ],
    G_SHORT_WEEKDAY_MONDAY: [
        'M',
        'T',
        'W',
        'T',
        'F',
        'S',
        'S',
    ],
    P_WEEKDAY: [
        'شنبه',
        'یکشنبه',
        'دوشنبه',
        'سه‌شنبه',
        'چهارشنبه',
        'پنجشنبه',
        'جمعه',
    ],
    P_SHORT_WEEKDAY: [
        'ش',
        'ی',
        'د',
        'س',
        'چ',
        'پ',
        'ج',
    ],
}
function getWeekDays (mode: string, weekStart: string)  {
    if (mode === 'j') return names.P_SHORT_WEEKDAY
    return weekStart === 'monday' ? names.G_SHORT_WEEKDAY_MONDAY : names.G_SHORT_WEEKDAY
}
export default {
   ...names,
    getWeekDays
}