import { Base } from '../../../../base/components/base'
import { Div } from '../../../../base/components/native/div'
import { Img } from '../../../../base/components/native/img'
import { Span } from '../../../../base/components/native/span'
import router from '../../../../base/lib/router'
import { IMAGES } from '../../../../configs/images'
import helpers from '../../../../helpers'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'

export const MonthTitle = (cursor: IDate, mode: TMode) => {

    const { jy, jm, gy, gm } = cursor
    const month = mode === 'g' ? helpers.names.G_MONTHS[gm - 1] : helpers.names.P_MONTHS[jm - 1]
    const year = mode === 'g' ? gy : helpers.digits.toPersian(jy)
    const yearSpan = Span(year.toString())
    const arrow = Img(IMAGES.ICONS.ARROW_DOWN, {width: 18})
    yearSpan.append(arrow)
    const base = Div(month)
    base.append(yearSpan)

    base.el.onclick = () => router.goto('/year')

    arrow.cssClass({
        margin: mode === 'g' ?'0px 6px 0 4px': '8px 6px 0 4px',
        opacity: '.8',
    })
    yearSpan.cssClass({
        border: '1px solid #ffffff69',
        borderRadius: '10px',
        padding: mode === 'g' ? '0px 6px 0 8px' : '3px 8px 10px 6px',
        margin: '0px 10px',
        // display: 'inline-block',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    })
    base.cssClass({
        fontFamily: mode === 'g' ? 'sans-serif' : 'Samim',
        fontSize: mode === 'g' ? '24px' : '28px',
        lineHeight: mode === 'g' ? '40px' : '25px',
        fontWeight: 'bold',
        textAlign: mode === 'g' ? 'left' : 'right',
        margin: mode === 'g' ? '40px 20px 6px' : '40px 20px 0px',
        color: 'white',
        height: '42px',
        opacity: '.9',
        direction: mode === 'g' ? 'ltr' : 'rtl',
    })

    return base
}