import { Div } from '../../base/components/native/div'
import emitter from '../../base/utils/emitter'
import config from '../../services/config'
import { SimpleSwitch } from '../shared/simple-switch'

export const MenuWeekStart = () => {

    const base = Div()
    const title = Div('شروع هفته میلادی')
    const weekStartSwitch = SimpleSwitch([
        { title: 'دوشنبه', value: 'monday' },
        { title: 'یکشنبه', value: 'sunday' },
    ])
    let c = config.getConfig()

    weekStartSwitch.on('change', (value: string) => {
        c = config.getConfig()
        config.setConfig({ ...c, weekStart: value })
        emitter.emit('set-week-start')
    })
    base.append(title, weekStartSwitch)
    setTimeout(() => {
        // Todo: add after mount event to simple switch
        weekStartSwitch.setValue(c.weekStart)
    }, 100);

    title.cssClass({
        textAlign: 'right',
        opacity: '.8',
        fontSize: '16px',
        marginBottom: '10px',
    })
    base.cssClass({
        margin: '10px 0 20px',
    })

    return base
}