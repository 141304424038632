import { ghostify } from "../../base/components/advanced/ghost/ghost"
import { Base } from "../../base/components/base"
import { Img } from "../../base/components/native/img"
import { Span } from "../../base/components/native/span"
import { CENTER, EASE, S } from "../../base/helpers/style"
import { ISimpleSwitchItem } from '../../interfaces/simple-switch'

export const SimpleSwitchItem = (item: ISimpleSwitchItem) => {

    const base = Base('span')
    if (item.icon) {
        const icon = Img(item.icon, { width: 18 })
        icon.cssClass({margin: '0 4px'})
        base.append(icon)
    }
    const title = Span(item.title)
    base.append(title)
    base.cssClass({
        letterSpacing: '-0.5px',
        width:'50%',
        height: '38px',
        display: 'inline-block',
        fontWeight: '100',
        textAlign: 'center',
        position: 'relative',
        paddingTop: '6px',
        zIndex: '2',
        opacity: '.7',
        color: 'white',
        ...EASE(.24)
    })

    ghostify(base, { bg: 'white', opacity: .1, activeStyle: { ...S(1) } })

    return Object.assign(
        base,
        {
            highlight() {
                base.style({
                    opacity: '1',
                    pointerEvents: 'none'
                })
            },
            dim() {
                base.style({
                    opacity: '.6',
                    pointerEvents: 'all'
                })
            }
        }
    )
}
