
import { Base } from '../../../base/components/base'
import { Div } from '../../../base/components/native/div'
import { ABSOLUTE, HIDE, EASE, SHOW, Y } from '../../../base/helpers/style'
import router, { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import { P_MONTHS_COLORS, G_MONTHS_COLORS } from '../../../helpers/p-months'
import { ISavedTodo } from '../../../interfaces/todo'
import { Todo } from '../../../models/todo'
import state from '../../../services/state'
import todos from '../../../services/todos'
import { EButton } from '../../shared/e-button'
import { EInput } from '../../shared/e-input'

export const EditTodoPage = () => {
    const base = Base()
    const title = Div('ویرایش کار')
    const titleInput = EInput('عنوان')
    const save = EButton('ثبت')
    const cancel = EButton('لغو')
    base.append(title, titleInput, save, cancel)

    cancel.el.onclick = router.back

    title.cssClass({
        fontSize: '20px',
        textAlign: 'right',
    })
    titleInput.cssClass({
        margin: '40px 0px',
    })
    save.cssClass({
        margin: '20px 0px',
    })

    base.cssClass({
        ...ABSOLUTE,
        padding: 'calc(env(safe-area-inset-top) + 55px) 20px',
        color: 'white',
        willChange: 'transform,opacity',
        ...HIDE,
        ...EASE(0),
        ...Y(70)
    })



    return {
        ...base,
        async exit({ to = '' }: IRouteParams) {
            base.style({
                ...EASE(.16),
                ...HIDE,
                ...Y(70)
            })
            titleInput.setValue('')
            await waitFor(350)
        },
        async enter({ from = '', data: { id} }: IRouteParams) {
            console.log('entering ediiiiit todo page', id)
            const todo = <ISavedTodo>await todos.byId(id)
            console.log('todo', todo)
            titleInput.setValue(todo.title)
            base.appendBefore(titleInput)
            // Todo: check if it's a holiday and due
            await waitFor(200)
            titleInput.focus()
            save.el.onclick = updateTodo
            title.once('key-enter', updateTodo) // Todo: not working

            function updateTodo() {
                const title = titleInput.getValue()
                if (title === '')  return
                todo.title = title
                todos.update(todo.id, todo)
                router.back()
            }

            base.style({
                backgroundColor: 'gray',
                ...SHOW,
                ...Y(0),
                ...EASE(.16)
            })
        }
    }
}