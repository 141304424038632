import { Base } from '../../../../base/components/base'
import state from '../../../../base/services/state'
import emitter from '../../../../base/utils/emitter'
import helpers from '../../../../helpers'
import { TMode } from '../../../../interfaces/calendar'
import { IDate } from '../../../../interfaces/date'
import calendar from '../../../../services/calendar'
import config from '../../../../services/config'
import { MonthCell } from './month-cell'
import { EmptyCell } from './month-cell-empty'
import { MonthWeekDay } from './month-cell-weekday'

export const MonthGrid = (cursor: IDate, mode: TMode, options = { today: {} }) => {

    const base = Base()
    const { orientation, weekStart } = config.getConfig()
    const { gy, gm, gd, jy, jm, jd, hy, hm, hd, wd } = cursor
    const gMonthLength = calendar.gMonthLength(gy, gm)
    const jMonthLength = calendar.jMonthLength(jy, jm)
    const hMonthLength = calendar.hMonthLength(hy, hm)
    const L = mode === 'g' ? gMonthLength : jMonthLength
    const WD = mode === 'j' ? (wd + 1) % 7 : weekStart === 'monday' ? (wd + 6) % 7 : wd
    let cellValues: IDate[] = []

    emitter.on('set-orientation', (orentation: string) => base.style({ flexDirection: orentation }))
    base.on('mounted', () => {
        const width = base.el.offsetWidth
        base.style({ height: `${width + 105}px` })
        fill()
    })

    function cellValue(day: number, cursor: IDate): IDate {
        const [gy, gm, gd] = getDayByIndex(day, cursor.gy, cursor.gm, cursor.gd, gMonthLength)
        const [jy, jm, jd] = getDayByIndex(day, cursor.jy, cursor.jm, cursor.jd, jMonthLength)
        const [hy, hm, hd] = getDayByIndex(day, cursor.hy, cursor.hm, cursor.hd, hMonthLength)
        const wd = (day + cursor.wd) % 7
        return { gy, gm, gd, jy, jm, jd, hy, hm, hd, wd }
    }

    function getDayByIndex(dayIndex: number, y: number, m: number, d: number, monthLength: number) {
        const nextDay = dayIndex + d
        if (nextDay > monthLength) {
            const nextMonth = m + 1
            if (nextMonth > 12) {
                return [y + 1, 1, nextDay - monthLength]
                // Urgent: for hijri if (nextMonth - monthLength >  the next month length) do something
            }
            return [y, nextMonth, nextDay - monthLength]
        }
        return [y, m, nextDay]
    }

    function fill() {
        const weekDays = helpers.names.getWeekDays(mode, weekStart).map((wd, i) => MonthWeekDay(mode, wd, i, orientation, weekStart))
        base.append(...weekDays)

        const emptyBefore = [...Array(WD)]
        emptyBefore.forEach(() => base.append(EmptyCell()))

        const days = [...Array(L)]
        days.forEach((_, i) => {
            const v = cellValue(i, cursor)
            cellValues.push(v)
            base.append(MonthCell(v, mode, {...options, hMonthLength}))
        })

        const emptyAfter = [...Array(42 - L - WD)]
        emptyAfter.forEach(() => {
            const cell = EmptyCell()
            // cell.style({ height: 'unset' })
            base.append(cell)
        })
    }
    
    base.cssClass({
        margin: '0 20px',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: orientation,
        direction: mode === 'j' ? 'rtl' : 'ltr',
        color: 'white',
        width: 'calc(100% - 40px)',
    })

    return Object.assign(base, {
        getDates: () => {
            return cellValues
        }
    })

}
