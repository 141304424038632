import { Base } from '../base/components/base'
import { COLORS } from '../configs/config'
import { ABSOLUTE } from '../base/helpers/style'

export const View = () => {

    const base = Base('div') // Fragment later

    base.cssClass({
        position: 'relative',
        height: '100vh',
        overflow: 'hidden',
        width: '100vw',
    })

    return base
}