import { Div } from '../../../../base/components/native/div'
import { SIZE } from '../../../../configs/size'

export const EmptyCell = () => {

    const base = Div()

    base.cssClass({
        marginTop: SIZE.MONTH_CELL.MARGIN_TOP,
        position: 'relative',
        width: 'calc(100% / 7)',
        height:  SIZE.MONTH_CELL.HEIGHT,
    })

    return base
}
