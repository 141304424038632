export const G_OCCASIONS = [
    27,  3, 'ج. تئاتر',
    2,   4, 'ج. کتاب کودک',
    7,   4, 'ج. بهداشت',
    12,  4, 'شب یوری',
    16,  4, 'ج. کتابداران ویژه',
    21,  4, 'ج. خلاقیت و نوآوری',
    22,  4, 'جشن گیاه آوری؛ روز زمین',
    23,  4, 'ج. کتاب',
    25,  4, 'ج. مالاریا',
    27,  4, 'ج. طراحی و گرافیک',
    1,   5, 'روزجهانی کارگر',
    3,   5, 'ج. آزادی مطبوعات',
    5,   5, 'ج. ماما',
    8,   5, 'ج. صلیب سرخ و هلال احمر',
    10,  5, 'ج. لوپوس',
    12,  5, 'ج. پرستار',
    13,  5, 'ج. مهندسی IEEE',
    15,  5, 'ج. خانواده',
    17,  5, 'ج. فشار خون',
    18,  5, 'ج. موزه و میراث فرهنگی',
    21,  5, 'ج. تنوع فرهنگی',
    22,  5, 'ج. تنوع زیستی',
    24,  5, 'ج. اسکیزوفرنی',
    31,  5, 'ج. بدون دخانیات',
    1,   6, 'ج. شیر',
    3,   6, 'ج. دوچرخه',
    5,   6, 'ج. محیط زیست',
    8,   6, 'ج. اقیانوس',
    10,  6, 'ج. صنایع دستی',
    12,  6, 'ج. مبارزه با کار کودکان',
    14,  6, 'ج. اهدای خون',
    16,  6, 'ج. کودک آفریقایی',
    17,  6, 'ج. بیابان زدایی',
    20,  6, 'ج. پناهندگان',
    21,  6, 'ج. یوگا',
    26,  6, 'ج. مبارزه با مواد مخدر',
    11,  7, 'ج. جمعیت',
    28,  7, 'ج. هپاتیت',
    1,   8, 'آغاز هفته جهانی شیردهی',
    7,   8, 'ج. دوستی',
    8,   8, 'ج. و بین المللی بی نهایت',
    9,   8, 'ج. مردم بومی جهان',
    10,  8, 'ج. بیودیزل',
    12,  8, 'ج. جوانان',
    13,  8, 'ج. چپ دست ها',
    19,  8, 'ج. عکاسی',
    23,  8, 'ج. یادآوری تجارت برده و لغو آن',
    8,   9, 'ج. سوادآموزی',
    10,  9, 'ج. پیشگیری از خودکشی',
    11,  9, 'حمله به برج‌های دوقلوی مرکز تجارت جهانی',
    13,  9, 'روز گرامیداشت برنامه نویسان',
    15,  9, 'روز آزادی نرم افزار',
    15,  9, 'ج. دموکراسی',
    16,  9, 'ج. حفاظت از لایه اوزون',
    21,  9, 'ج. صلح',
    22,  9, 'ج. بدون خودرو',
    27,  9, 'ج. جهانگردی',
    29,  9, 'روز مخترعان',
    29,  9, 'ج. قلب',
    30,  9, 'ج. ناشنوایان',
    30,  9, 'ج. ترجمه و مترجم',
    1,  10, 'ج. سالمندان',
    1,  10, 'ج. موسیقی',
    1,  10, 'ج. باله',
    2,  10, 'ج. حیوانات مزرعه',
    3,  10, 'ج. اعتدال',
    4,  10, 'ج. حیوانات',
    5,  10, 'ج. معلم',
    9,  10, 'ج. پست',
    10, 10, 'ج. مبارزه با حکم اعدام',
    10, 10, 'ج. بهداشت روان',
    11, 10, 'ج. دختر',
    14, 10, 'ج. استاندارد',
    15, 10, 'ج. عصای سفید',
    16, 10, 'ج. غذا',
    17, 10, 'ج. ریشه کنی فقر',
    24, 10, 'روز سازمان ملل متحد',
    29, 10, 'ج. اینترنت',
    8,  11, 'ج. شهرسازی',
    14, 11, 'ج. دیابت',
    16, 11, 'ج. مدارا',
    18, 11, 'ج. فلسفه',
    19, 11, 'ج. آقایان',
    20, 11, 'ج. کودک',
    25, 11, 'ج. مبارزه با خشونت علیه زنان',
    30, 11, 'ج. امنیت کامپیوتر',
    1,  12, 'ج. ایدز',
    2,  12, 'ج. لغو برده داری',
    3,  12, 'ج. معلولان',
    5,  12, 'ج. داوطلبان',
    7,  12, 'ج. هوانوردی غیرنظامی',
    10, 12, 'روز حقوق بشر',
    11, 12, 'ج. کوه نوردی',
    25, 12, 'جشن کریسمس',
    25, 12, 'روز بزرگداشت دوستی',
    1,  1, 'جشن آغاز سال نو میلادی',
    1,  1, 'ج. خانواده',
    4,  1, 'ج. خط بریل',
    11, 1, 'ج. تشکر',
    30, 1, 'ج. جذام',
    11, 2, 'حمله به سفارت روسیه و قتل گریبایدوف سفیر روسیه تزاری در ایران',
    12, 2, 'زادروز چارلز داروین بنیانگذار نظریه‌ی فرگشت از طریق انتخاب طبیعی',
    14, 2, 'جشن ولنتاین',
    21, 2, 'ج. زبان مادری',
    22, 2, 'ج. تفکر',
    8,  3, 'روزجهانی زنان',
    14, 3, 'ج. عدد پی π',
    20, 3, 'ج. شادی',
    21, 3, 'ج. سندروم داون',
    22, 3, 'ج. آب',
    23, 3, 'ج. هواشناسی',
    24, 3, 'ج. سل',
    25, 3, 'ساعت زمین'
]