import { ghostify } from '../../../base/components/advanced/ghost/ghost'
import { Img } from '../../../base/components/native/img'
import { Span } from '../../../base/components/native/span'
import { Base} from '../../../base/components/base'
import { EASE, S } from '../../../base/helpers/style'

export const SwitchItem = (_title: string) => {

    const base = Base('div')
    const title = Span(_title)
    base.append(title)
    base.cssClass({
        position: 'relative',
        textAlign: 'center',
        zIndex: '2',
        opacity: '.7',
        color: 'white',
        width: '50%',
        ...EASE(.24)
    })

    ghostify(base, { bg: 'white', opacity: .1, activeStyle: { ...S(1) } })

    return Object.assign(
        base,
        {
            highlight() {
                base.style({
                    opacity: '1',
                    pointerEvents: 'none'
                })
            },
            dim() {
                base.style({
                    opacity: '.6',
                    pointerEvents: 'all'
                })
            }
        }
    )
}
